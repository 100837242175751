import useSWR from 'swr'
import { Text, Box, Button, Flex, Group, Input, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { IconSearch } from '@tabler/icons-react';
import { useAppStore } from "../../components/useAppStore"
import useBackendRequests from '../../components/useBackendRequests';
import dayjs from 'dayjs';
import { showNotification } from '@mantine/notifications';

// When input is focused, select all text
const selectAll = (e) => {
  e.target.select();
}

export default function WrittenTestGrade({ testId = 2 }) {
  const { setHeaderTitle } = useAppStore();
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const searchInputRef = useRef({ value: "" });
  const firstScoreInputRef = useRef();
  const { fetcher, postJson } = useBackendRequests();
  const { data } = useSWR((debouncedQuery ? `/entrants/search/${debouncedQuery}` : null), fetcher);
  // key is entrant pin, value is score. I want to use pin instead of id because it's more useful when backed up in local storage
  const [scores, setScores] = useState({});

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    setHeaderTitle("Erialatestide hindamine");
    return () => {
      setHeaderTitle("");
    }
  }, [setHeaderTitle]);

  // Reset scores when query changes
  useEffect(() => {
    setScores({});
  }, [debouncedQuery]);

  const saveScore = async (entrantId, entrantPin) => {
    const score = scores[entrantPin];
    console.log("Saving score", entrantId, testId, score);
    if (score === undefined || score === null || score === "") {
      showNotification({
        message: "Palun sisesta hinne",
        color: "red",
        autoClose: 3000,
      });
      return;
    }

    // Backup score entry to the local storage.
    // Make it pin=score&pin2=score2 string for easier appending than parsing json
    const scoreString = `${entrantPin}=${score}`;
    const currentScores = localStorage.getItem("writtenTestScores");
    if (currentScores) {
      localStorage.setItem("writtenTestScores", `${currentScores}&${scoreString}`);
    } else {
      localStorage.setItem("writtenTestScores", scoreString);
    }

    // send request and show notification on success
    postJson(`/entrant-tests/end`, { entrantId, testId, score, keepSameRoom: true }).then(() => {
      showNotification({
        title: "Punktid salvestatud",
        autoClose: 3000,
      });
      setQuery("");
      searchInputRef.current.focus();
    });
  }

  // when data changes check if there is only one result make first result input focused
  useEffect(() => {
    if (data?.length === 1 && firstScoreInputRef?.current) {
      firstScoreInputRef?.current?.focus();
    }
  }, [data]);

  return (
    <>
      <Text fz="md" mb="xs">Sisesta kandideerija isikukood või otsi nime järgi</Text>
        <Flex mb="md">
          <TextInput
            ref={searchInputRef}
            sx={{ flex: 1 }}
            placeholder="Otsi kõikide kandidaatide hulgast"
            icon={<IconSearch size={16} />}
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
            size='md'
            ref={inputRef}
          />
          <Button size="md" variant="outline" ml="sm" onClick={(e) => setQuery(searchInputRef.current.value)}>
            Otsi
          </Button>
      </Flex>

      <Box sx={{ height: 300 }}>
        <DataTable
          withBorder
          highlightOnHover
          striped
          horizontalSpacing="sm"
          records={data}
          columns={[
            { accessor: 'name', render: ({ firstName, lastName }) => `${firstName} ${lastName}`, title: 'Nimi' },
            { accessor: 'pin', title: 'Isikukood' },
            {
              accessor: 'EntrantTests.returnedAt', title: 'Tõi vihiku tagasi',
              render: ({ EntrantTests }) => {
                const currentTest = EntrantTests.find((test) => test.testId === testId);
                return currentTest?.returnedAt ? dayjs(currentTest.returnedAt).format('dddd, DD.MM. HH:mm') : 'Ei ole lõpetanud';
              }
            },
            {
              accessor: 'EntrantTests.score', title: 'Punktid',
              render: ({ EntrantTests }) => {
                const currentTest = EntrantTests.find((test) => test.testId === testId);
                return currentTest?.gradedBy ? currentTest?.score : 'Ei ole hinnatud';
              }
            },
            {
              accessor: 'id', title: 'Muuda punkte',
              render: ({ id, pin }, index) => {
                return <Group>
                  <Input
                    type="number"
                    ref={index === 0 ? firstScoreInputRef : null}
                    onChange={(e) => setScores({ ...scores, [pin]: e.currentTarget.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        saveScore(id, pin);
                      }
                    }}
                    onFocus={selectAll}
                    style={{ width: 50 }}
                  />
                  <Button
                    variant={scores[pin] ? "filled" : "outline"}
                    size="xs"
                    color="blue"
                    onClick={() => saveScore(id, pin)}
                  >
                    Kinnita
                  </Button>
                </Group>
              }
            },
          ]}
        />
      </Box>
    </>
  );
}