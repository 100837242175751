import { SWRConfig } from "swr/_internal"
import { useLocalStorage, useMediaQuery } from "@mantine/hooks"
import { MantineProvider } from '@mantine/core';
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Notifications } from "@mantine/notifications"
import Settings from "./views/settings/Settings"
import dayjs from "dayjs"
import 'dayjs/locale/et';
import "./App.css";
import { fetcher } from "./components/fetcher"
import Dashboard from "./views/dashboard/Dashboard"
import EditEntrants from "./views/entrants/EditEntrants"
import ListEntrants from "./views/entrants/ListEntrants"
import Layout from "./components/AppShell"
import TestsPoC from "./views/tests/TestsPoC"
import Login from "./views/login/Login"
import InterviewPoC from "./views/tests/InterviewPoC"
import PracticalTestPoC from "./views/tests/PracticalTestPoC"
import WrittenTestPoC from "./views/tests/WrittenTestPoC"
import InterviewForm from "./views/tests/InterviewForm"
import ImportEntrants from "./views/entrants/ImportEntrants";
import ExportEntrants from "./views/entrants/ExportEntrants";
import InvalidTest1Responses from "./views/tests/InvalidTest1Responses";
import WrittenTestGrade from "./views/tests/WrittenTestGrade";
import YldtestLogin from "./views/login/YldtestLogin";

export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || window.BACKEND_API_URL || "http://localhost:3001/api";
dayjs.locale('et')

function App() {
  let [colorScheme] = useLocalStorage({ key: 'colorScheme', defaultValue: 'auto' });
  const preferredColorScheme = useMediaQuery("(prefers-color-scheme: dark)");

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <TestsPoC /> },
        { path: "entrants", element: <ListEntrants /> },
        { path: "entrants/import", element: <ImportEntrants /> },
        { path: "entrants/export", element: <ExportEntrants /> },
        { path: "entrants/:id", element: <EditEntrants /> },
        { path: "tests", element: <TestsPoC /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "practical", element: <PracticalTestPoC /> },
        { path: "written", element: <WrittenTestPoC /> },
        { path: "written/grade", element: <WrittenTestGrade /> },
        { path: "interview", element: <InterviewPoC /> },
        { path: "interview/:id", element: <InterviewForm /> },
        { path: "invalid-responses", element: <InvalidTest1Responses /> },
        { path: "settings", element: <Settings /> },
      ]
    },
    { path: "/login", element: <Login /> },
    { path: "/alusta", element: <YldtestLogin /> }
  ]);

  return (
    <div>
      <SWRConfig value={{ fetcher: fetcher }}>
        <MantineProvider
          theme={{
            colorScheme: colorScheme === 'auto' ? preferredColorScheme ? 'dark' : 'light' : colorScheme,
            fontFamily: 'Noto Sans, sans serif',
            colors: {
              blue: ['#E9fAFF', '#CDF1FF', '#A4DEF9', '#75D1F7', '#31C5F4', '#4CA6DC','#5B88C6', '#3D6CB3', '#2A3060', '#11132D'],
              green: ['#EDF4D7', '#CAE2A3', '#A7D271', '#84C441', '#66B648', '#3FAB4F','#009859', '#00844B', '#00713E', '#015E31'],
              red: ['#F5DBEA', '#F6CADF', '#D45AA1', '#D61789', '#DD1B6F', '#E41E54', '#EB1E34', '#CA1D3A', '#AF1C3E', '#961A40s'],
            },
            spacing: { xs: '.5rem', sm: '1.2rem', md: '1.8rem', lg: '2.2rem', xl: '4.2rem' },
            fontSizes: {
              xs: '0.8rem',
              sm: '1rem',
              md: '1.4rem',
              lg: '2rem',
              xl: '3.2rem',
            },
            globalStyles: (theme) => ({
              body: {
                ...theme.fn.fontStyles(),
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.blue[9],
              },
              main: {
                background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
                paddingTop: 'calc(var(--mantine-header-height, 0px) + 2rem)',
                paddingBottom: 'calc(var(--mantine-footer-height, 0px) + 1rem)',
                paddingLeft: 'calc(var(--mantine-navbar-width, 0px) + 1rem)',
                paddingRight: 'calc(var(--mantine-aside-width, 0px) + 1rem)',
              },
              header: {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
              },
              [`#logo-txt`]: {
                fill: theme.colorScheme === 'dark' ? theme.white : theme.colors.blue[8],
              },
              [`#logo-icon`]: {
                fill: theme.colorScheme === 'dark' ? theme.white : theme.colors.blue[6],
              },
              [`#logo-icon-bg`]: {
                fill: theme.colorScheme === 'dark' ? theme.colors.blue[6] : theme.white,
              },
              
            }),
            components: {},
          }}
        >
          <Notifications position="top-right" />
          <RouterProvider router={router} />
        </MantineProvider>
      </SWRConfig>
    </div>
  );
}

export default App;
