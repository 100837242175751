import { Burger, Card, Group, Header, MediaQuery, Text, useMantineTheme } from "@mantine/core"
import { useAppStore } from "./useAppStore"

export default function ResponsiveHeader({ isLargeScreen }) {
  const theme = useMantineTheme();
  const { menuOpen, toggleMenuOpen, headerTitle, sortedHeaderActions } = useAppStore();
  const HeaderComponent = isLargeScreen ? Card : Header;

  return <HeaderComponent p="sm" style={{
    position: "sticky",
    width: 'calc(100vw - var(--mantine-navbar-width))',
  }}>
    <Group spacing="sm" position="apart">
      <Group spacing="sm" position="left">
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Burger
            opened={menuOpen}
            onClick={toggleMenuOpen}
            size="sm"
            color={theme.colors.gray[6]}
          />
        </MediaQuery>
        <Text fz="md" fw="700" ml="xs">{headerTitle}</Text>
      </Group>
      <div>{sortedHeaderActions.map(([, value]) => value.element)}</div>
    </Group>
  </HeaderComponent>
}