import {AppShell, useMantineTheme} from "@mantine/core"
import Navigation from "./Navigation"
import {Outlet} from "react-router-dom"
import {useMediaQuery} from "@mantine/hooks"
import ResponsiveHeader from "./ResponsiveHeader"
import ScrollToTop from "./ScrollToTop"

export default function Layout() {
  const theme = useMantineTheme();
  const isLargeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);

  return (
    <AppShell
      layout="alt"
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={<Navigation />}
      header={!isLargeScreen && <ResponsiveHeader isLargeScreen={isLargeScreen} />}
    >
      {isLargeScreen && <ResponsiveHeader isLargeScreen={isLargeScreen} />}
      <ScrollToTop />
      <Outlet />
    </AppShell>
  );
}