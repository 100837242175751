import { Button, Card, Checkbox, Grid, NumberInput, Radio, Select, Stack, Text, Textarea, createStyles } from '@mantine/core';
import { useEffect, useMemo, useCallback, useState } from "react"
import { useAppStore } from "../../components/useAppStore"
import { useNavigate, useParams } from "react-router-dom"
import useSWR from "swr"
import { isNotEmpty, useForm } from "@mantine/form"
import useBackendRequests from '../../components/useBackendRequests';
import { useLocalStorage } from '@mantine/hooks';
import useSession from '../../components/useSession';
import dayjs from 'dayjs';
import { showNotification } from '@mantine/notifications';
import { useTimer } from "react-use-precision-timer";

const fallbackData = {
  firstName: "",
  lastName: "",
  pin: "",
  program: "",
  nextRoom: "",
  EntrantTests: [],
}

const maxScore = 25;
// Max punktid mis saab anda kui õpilasel on järgnev negatiivne omadus:
const scoring = {
  napisõnaline: 20,
  erialaülesanded: 20,
  tühiMotivatsioonikiri: 20,
  umbmäärane: 15,
  väheneHuvi: 10,
  ülbe: 5,
  hev: 0,
  negatiivsedHoiakud: 0,
  keeleJaKõne: 0,
}

// Iga negatiivne omadus vähendab maksimum punkte
// String true on radio buttonite jaoks, boolean true on checkboxide jaoks
const calculateScore = (values) => {
  let score = maxScore;
  Object.entries(scoring).forEach(([key, value]) => {
    if (values.results[key] === "true" || values.results[key] === true) {
      score = Math.min(score, value);
    }
  })
  return score;
}

const programs = [
  { value: 'Sisetööde elektrik', label: 'Sisetööde elektrik', group: 'Energeetika ja automaatika erialaosakond' },
  { value: 'Automaatik', label: 'Automaatik', group: 'Energeetika ja automaatika erialaosakond' },
  { value: 'IT süsteemide spetsialist', label: 'IT süsteemide spetsialist', group: 'IT ja telekommunikatsiooni erialaosakond' },
  { value: 'Tarkvaraarendaja', label: 'Tarkvaraarendaja', group: 'IT ja telekommunikatsiooni erialaosakond' },
  { value: 'Telekommunikatsiooni nooremspetsialist', label: 'Telekommunikatsiooni nooremspetsialist', group: 'IT ja telekommunikatsiooni erialaosakond' },
  { value: 'Elektroonikaseadmete tehnik', label: 'Elektroonikaseadmete tehnik', group: 'IT ja telekommunikatsiooni erialaosakond' },
  { value: 'Trükitehnoloogia (trükiste ettevalmistaja)', label: 'Trükitehnoloogia (trükiste ettevalmistaja)', group: 'Meedia erialaosakond' },
  { value: 'Trükitehnoloogia (ofsettrükkal)', label: 'Trükitehnoloogia (ofsettrükkal)', group: 'Meedia erialaosakond' },
  { value: 'Multimeedia spetsialist', label: 'Multimeedia spetsialist', group: 'Meedia erialaosakond' },
  { value: 'Visuaalmeedia spetsialist', label: 'Visuaalmeedia spetsialist', group: 'Meedia erialaosakond' },
  { value: 'Lavavalgustehnik', label: 'Lavavalgustehnik', group: 'Meedia erialaosakond' },
  { value: 'Fotograaf', label: 'Fotograaf', group: 'Meedia erialaosakond' },
  { value: 'Teleoperaator', label: 'Teleoperaator', group: 'Meedia erialaosakond' },
]

/*
First we try to match letters before "-", when no match we remove all numbers and try full text match
Eg:
a) TA-22V -> TA (match)
b) TT-22E -> TT (no match), TT-E (match)
 */
const codeToProgramMap = {
  EA: "Sisetööde elektrik",
  EV: "Sisetööde elektrik",
  KEE: "Sisetööde elektrik",
  KEV: "Sisetööde elektrik",
  KSE5: "Sisetööde elektrik tase 5",
  TJE: "Jaotusvõrgu elektrik",
  KJE5: "Jaotusvõrgu elektrik tase 5",
  AA: "Automaatik",
  AV: "Automaatik",
  IT: "IT süsteemide spetsialist",
  KIT: "IT süsteemide spetsialist",
  TA: "Tarkvaraarendaja",
  KTA: "Tarkvaraarendaja",
  SA: "Telekommunikatsiooni nooremspetsialist",
  LA: "Elektroonikaseadmete tehnik",
  "TT-E": "Trükitehnoloogia (trükiste ettevalmistaja)",
  "KTS-E": "Trükitehnoloogia (trükiste ettevalmistaja)",
  "TT-T": "Trükitehnoloogia (ofsettrükkal)",
  "KTS-T": "Trükitehnoloogia (ofsettrükkal)",
  MM: "Multimeedia spetsialist",
  KMS: "Multimeedia spetsialist",
  VM: "Visuaalmeedia spetsialist",
  KLT: "Lavavalgustehnik",
  FS: "Fotograaf",
  KTO: "Teleoperaator",
}

function codeToProgram(programCode) {
  // split by "-" and take first part
  const program = codeToProgramMap[programCode.split('-')[0]];
  if (program) {
    return program;
  } else {
    const programCodeWithoutNumbers = programCode.replace(/\d/g, '');
    return codeToProgramMap[programCodeWithoutNumbers];
  }
}

const formatDate = (date) => date ? dayjs(date).format("dddd DD.MM.YYYY HH:mm") : '';

const useStyles = createStyles((theme) => ({
  justifyRight: {
    [`& > .mantine-Radio-body`]: {
      justifyContent: "flex-end",
    }
  },
  radioGroup: {
    marginTop: 5,
    padding: '5 0',
    borderRadius: theme.radius.lg,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2],
    border: `5px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2]}`,
    [`.mantine-Grid-col:first-of-type`]: {
      paddingRight: '0',
    },
    [`.mantine-Grid-col:last-of-type`]: {
      paddingLeft: '0',
    },
  },
  radioItem: {
    height: '100%',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2],
    borderRadius: theme.radius.lg,
    position: 'relative',
    [`&[data-checked=true]`]: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.white,
    },
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2]}`,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.white,
      border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2]}`,
    },
    [`.mantine-Radio-label`]: {
      padding: theme.spacing.sm,
      borderRadius: theme.radius.md,
      paddingLeft: '44px'
    },
    [`.mantine-Radio-inner`]: {
      position: 'absolute',
      top: 20,
      left: 12,
    },
    [`*:not(.mantine-Radio-inner, svg, input)`]: {
      height: '100%',
      cursor: 'pointer',
      flex: '1'
    },
  },
  spacedList: {
    '& li': {
      marginBottom: theme.spacing.xs,
    }
  },
}));

export default function InterviewForm({ testId = 3 }) {
  const { setHeaderTitle, addHeaderAction, removeHeaderAction } = useAppStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isAdmin } = useSession();
  const { fetcher, postJson } = useBackendRequests();
  const { data: settings } = useSWR("/settings", fetcher, {
    fallbackData: {},
  });
  const { data: entrant, isLoading, mutate } = useSWR(`/entrants/${id}`, fetcher, {
    fallbackData,
  });
  const [myRoom] = useLocalStorage({ key: 'interviewTestRoom', defaultValue: '' });
  const fullName = `${entrant.firstName} ${entrant.lastName}`;
  const { classes } = useStyles();
  const isCorrectRoom = myRoom === entrant.nextRoom;
  // eslint-disable-next-line eqeqeq
  const currentTest = entrant.EntrantTests.find(test => test.testId == testId);
  const hasStarted = currentTest?.startedAt != null;
  const hasEnded = currentTest?.returnedAt != null;

  // Stopwatch
  const [, setUpdateStopwatch] = useState(true);
  const callback = useCallback(() => setUpdateStopwatch(prevState => !prevState), [setUpdateStopwatch]);
  const timer = useTimer({ delay: 1000 }, callback);
  useEffect(() => {
    if (hasStarted && !hasEnded && timer) {
      timer.start();
    }
  }, [timer, hasStarted, hasEnded]);
  const formatStopwatch = useCallback((endedAt) => {
    let seconds = dayjs(endedAt).diff(dayjs(currentTest?.startedAt), 'second');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    seconds = seconds % 60;
    return `${hours > 0 ? hours + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [currentTest?.startedAt])

  const handleStartInterview = () => {
    postJson(`/entrant-tests/start`, {
      entrantId: entrant.id,
      testId: testId,
      room: myRoom,
    }).then(() => {
      mutate(); // To update the acceptance part of the UI and to confirm that the interview has started
    });
  }

  const requiredErrorMessage = "See valik on kohustuslik";
  const form = useForm({
    initialValues: {
      results: {
        napisõnaline: null,
        umbmäärane: null,
        väheneHuvi: null,
        ülbe: null,
        hev: null,
        negatiivsedHoiakud: false,
        keeleJaKõne: false,
        tühiMotivatsioonikiri: false,
        teineEriala: false,
        erialaülesanded: false,
        soovituslikEriala: null,
        punktidKomisjonilt: 0,
        triedLoading: false, // To avoid overwriting the real data during mount I check if the form has been saved to the local storage or not, 
      },
      score: 0,
      comments: "",
    },
    validate: {
      results: {
        napisõnaline: isNotEmpty(requiredErrorMessage),
        umbmäärane: isNotEmpty(requiredErrorMessage),
        väheneHuvi: isNotEmpty(requiredErrorMessage),
        ülbe: isNotEmpty(requiredErrorMessage),
        hev: isNotEmpty(requiredErrorMessage),
        soovituslikEriala: (value, values) => {
          // soovituslikEriala is required only when teineEriala is true
          if (values.results?.teineEriala === true) {
            return isNotEmpty(requiredErrorMessage)(value);
          }
          return null;
        }
      },
      comments: (value, values) => {
        if (value?.trim().length < 10 && values.results.punktidKomisjonilt <= 10) {
          return "Nii väheste puntkidega on selgitus kohustuslik";
        }
        return null;
      },
    },
  })

  const saveInterviewForm = (values) => {
    form.validate();
    console.log("submitting form", form.isValid(), form.values, form.errors);
    if (form.isValid()) {
      const score = calculateScore(values);
      delete values.results.triedLoading; // remove local storage flag
      values.results.punktidValikutest = score;
      values.results.punktidKomisjonilt = Math.round(values.results.punktidKomisjonilt / 5) * 5;
      values.score = values.results.punktidKomisjonilt; // Also save the top level score
      values.testId = testId;
      values.entrantId = entrant.id;
      if (values.results.teineEriala === false) {
        values.results.soovituslikEriala = null;
      }
      postJson("/entrant-tests/end", values).then(() => {
        showNotification({
          message: "Salvestatud",
          autoClose: 5000,
        });
        form.setValues(values);
        form.resetDirty(values);
        //mutate(); // Reload entrant data to update UI
        navigate(`/interview`);
      });
    } else {
      showNotification({
        message: "Vormil on vigu",
        autoClose: 4000,
        color: "red",
      });
    }
  }

  const programName = useMemo(() => entrant.program ? codeToProgram(entrant.program) : "", [entrant.program]);
  useEffect(() => {
    setHeaderTitle(`Vestlus ${fullName} ${entrant.pin} ${entrant.program} ${programName}`);
    if (hasStarted) {
      addHeaderAction({
        name: "save-interivew",
        element: <Button key="save-interivew" variant={form.isDirty() ? "filled" : "outline"} onClick={() => saveInterviewForm(form.values)}>
          Salvesta{!hasEnded ? ' ja lõpeta vestlus' : ''}
        </Button>,
        order: 1,
      });
    }

    return () => {
      setHeaderTitle("");
      removeHeaderAction("save-interivew");
    }
  }, [form.values, entrant, hasStarted, hasEnded, setHeaderTitle, addHeaderAction, removeHeaderAction]);

  // Fill form with existing values
  useEffect(() => {
    if (entrant?.pin) {
      // Check existing values from the database first
      if (currentTest?.returnedAt && currentTest?.results) {
        // Results part is stored as a string in the database
        let resultsObj = JSON.parse(currentTest.results);
        if (resultsObj) {
          // I know that currentTest has more fields than just form fields and those will be
          // submitted during save, but backend will ignore them.
          // You can filter them out based on initial values if needed.
          resultsObj.triedLoading = true;
          let values = { ...currentTest, results: resultsObj };
          form.setValues(values);
          form.resetDirty(values);
          return;
        }
      }
      // Try local storage if there's no data from the database
      const storedValue = window.localStorage.getItem('x-entrant-interview-form-' + entrant.pin);
      if (storedValue) {
        try {
          const values = JSON.parse(storedValue);
          values.results.triedLoading = true;
          form.setValues(values);
          form.resetDirty(values);
          return;
        } catch (e) {
          console.log('Failed to parse stored value');
        }
      }

      form.setFieldValue('results.triedLoading', true);
    }
  }, [entrant?.pin, currentTest?.results]);

  // Update local storage when form values change
  useEffect(() => {
    if (entrant?.pin && form.values.results.triedLoading) {
      window.localStorage.setItem('x-entrant-interview-form-' + entrant.pin, JSON.stringify(form.values));
    }
  }, [form.values]);

  // Room confirmation before starting the interview
  if (!hasStarted) {
    return (
      <Card>
        <Stack spacing="xs">
          {isLoading ? "Palun oota, laen andmeid" : <>
            <Text>Vestlust pole alustatud</Text>
            {isCorrectRoom && <Text color="green">Õpilane on õiges ruumis</Text>}
            {!isCorrectRoom && <Text color="red">Õpilane peaks olema ruumis {entrant.nextRoom}</Text>}
            <Button disabled={!(isCorrectRoom || settings?.ignoreRoom === "true")} onClick={handleStartInterview}>Alusta vestlust</Button>
          </>}
        </Stack>
      </Card >
    )
  }

  //const score = calculateScore(form.values)

  return (
    <Grid>
      <Grid.Col sm={12} md={6} lg={6}>
        <Card>
          <h3>Vestluse tulemused</h3>
          {/*isAdmin && <Text>Punkte: {Math.max(score, 0)}/{maxScore}</Text>*/}
          <form>
            <Radio.Group required {...form.getInputProps('results.napisõnaline')} className={classes.radioGroup}>
              <Grid justify="center" gutter="xs">
                <Grid.Col span={6}><Radio
                  className={[classes.justifyRight, classes.radioItem]}
                  color="red"
                  value="true"
                  labelPosition="left"
                  label="Napisõnaline, peame suunama vestlust"
                /></Grid.Col>
                <Grid.Col span={6}><Radio
                  className={classes.radioItem}
                  color="green"
                  value="false"
                  labelPosition="right"
                  label="Räägib oma huvidest ise, avatud suhtleja"
                /></Grid.Col>
              </Grid>
            </Radio.Group>
            <Radio.Group required {...form.getInputProps('results.umbmäärane', { type: 'radio' })} className={classes.radioGroup}>
              <Grid justify="center">
                <Grid.Col span={6}>
                  <Radio
                    className={[classes.justifyRight, classes.radioItem]}
                    color="red"
                    value="true"
                    labelPosition="left"
                    label="Üksikud vastused on umbmäärased" />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Radio
                    className={classes.radioItem}
                    color="green"
                    value="false"
                    labelPosition="right"
                    label="Vastab kõigile küsimustele selgelt" />
                </Grid.Col>
              </Grid>
            </Radio.Group>
            <Radio.Group required {...form.getInputProps('results.väheneHuvi', { type: 'radio' })} className={classes.radioGroup}>
              <Grid justify="center">
                <Grid.Col span={6}>
                  <Radio
                    className={[classes.justifyRight, classes.radioItem]}
                    color="red"
                    value="true"
                    labelPosition="left"
                    label="Pole selget huvi õppima tulla" />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Radio
                    className={classes.radioItem}
                    color="green"
                    value="false"
                    labelPosition="right"
                    label="On motiveeritud õppima ja erialast huvitatud" />
                </Grid.Col>
              </Grid>
            </Radio.Group>
            <Radio.Group required {...form.getInputProps('results.ülbe', { type: 'radio' })} className={classes.radioGroup}>
              <Grid justify="center">
                <Grid.Col span={6}>
                  <Radio
                    className={[classes.justifyRight, classes.radioItem]}
                    color="red"
                    value="true"
                    labelPosition="left"
                    label="On selgelt ülevolev ja ülbe" />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Radio
                    className={classes.radioItem}
                    color="green"
                    value="false"
                    labelPosition="right"
                    label="Käitub normaalselt" />
                </Grid.Col>
              </Grid>
            </Radio.Group>
            <Radio.Group required {...form.getInputProps('results.hev', { type: 'radio' })} className={classes.radioGroup}>
              <Grid justify="center">
                <Grid.Col span={6}>
                  <Radio
                    className={[classes.justifyRight, classes.radioItem]}
                    color="red"
                    value="true"
                    labelPosition="left"
                    label="HEV tunnustega" />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Radio
                    className={classes.radioItem}
                    color="green"
                    value="false"
                    labelPosition="right"
                    label="HEV tunnused puuduvad" />
                </Grid.Col>
              </Grid>
            </Radio.Group>
            <Checkbox
              mt="sm"
              pl="xs"
              color="red"
              label={`Negatiivsed hoiakud õppimisse, kutsekooli, ei soovi tegelikult kandideerida`}
              {...form.getInputProps('results.negatiivsedHoiakud', { type: 'checkbox' })}
            />
            <Checkbox
              mt="sm"
              mb="lg"
              pl="xs"
              color="red"
              label={`Ei saa eesti keelest aru, muu kõneprobleem`}
              {...form.getInputProps('results.keeleJaKõne', { type: 'checkbox' })}
            />
            <Checkbox
              mt="sm"
              mb="lg"
              pl="xs"
              color="red"
              label={`Ei proovinud vihikust kõiki erialaga seotud ülesandeid lahendada`}
              {...form.getInputProps('results.erialaülesanded', { type: 'checkbox' })}
            />
            <Checkbox
              mt="sm"
              mb="lg"
              pl="xs"
              color="red"
              label={`Ei kirjutanud vihikus motivatsioonikirja või on vähem kui 2-3 lauset (AI teema)`}
              {...form.getInputProps('results.tühiMotivatsioonikiri', { type: 'checkbox' })}
            />
            <NumberInput
              mt="sm"
              label="Punktid"
              description=""
              max={25}
              min={0}
              step={5}
              formatter={value => Math.round(value / 5) * 5}
              {...form.getInputProps('results.punktidKomisjonilt', { type: 'number' })}
            />
            <Textarea
              mt="sm"
              autosize
              minRows={3}
              maxRows={7}
              label="Kommentaarid vestluse osas"
              error={form.values.comments.length > 2500 ? "Maksimaalselt on lubatud 2500 tähemärki" : null}
              {...form.getInputProps('comments', { type: 'textarea' })}
            />
            <Checkbox
              mt="sm"
              mb="lg"
              pl="xs"
              label={`Meie arvates sobiks õpilane rohkem teisele erialale`}
              {...form.getInputProps('results.teineEriala', { type: 'checkbox' })}
            />
            {form.values.results.teineEriala && <Select
              mt="sm"
              mb="lg"
              label="Soovituslik eriala (õpilasele midagi lubada ei tohi, asutusesiseseks kasutamiseks)"
              placeholder="Vali üks"
              searchable
              nothingFound="Ei leitud"
              data={programs}
              {...form.getInputProps('results.soovituslikEriala', { type: 'select' })}
            ></Select>}
          </form>
        </Card>
      </Grid.Col>
      <Grid.Col sm={12} md={6} lg={6}>
        <Card>
          <h3>Näpunäited vestluse läbiviimiseks</h3>
          <ul className={classes.spacedList}>
            <li>Tutvustage alguses komisjoni</li>
            <li>Andke pikalt aega isegi kui ta vahepeal vaikib või vastab ühe sõnaga</li>
            <li>Räägime aeglaselt</li>
            <li>Vältida "kas" küsimusi, millele saab vastata ainult "jah" või "ei"</li>
            <li>Vältida "miks" küsimusi, kuna need võivad õpilasi endasse sulgeda</li>
            <li>Lugege vihikust õpilase motivatsioonikirja. Kui ei saa käekirjast aru või on tühi, paluda rääkida</li>
          </ul>
          Vestlus õpilaskandidaadiga toimub järgmistel teemadel:
          <ul className={classes.spacedList}>
            <li>Lühike enesetutvustus (tervitus, nimi, mis erialale kandideerib)</li>
            <li>Meie kool ja eriala
              <ul className={classes.spacedList}>
                <li>Kuidas jõudsid selle erialavalikuni?</li>
                <li>Mis sa sellest erialast juba tead?</li>
                <li>Kuidas leidsid meie kooli?</li>
                <li>Mille poolest sa just Tallinna Polütehnikumi tahad kandideerida, mitte mujale?</li>
                <li>Kuidas oled ennast selleks erialaks õppimiseks ette valmistanud?</li>
              </ul>
            </li>
            <li>Sisseastumise testid
              <ul className={classes.spacedList}>
                <li>Räägi, millised ülesanded sulle testides meeldisid/ei meeldinud.</li>
                <li>Mida sa arvad programmeerimise ülesandest?</li>
                <li>Milline ülesanne oli kõige lihtsam / raskem?</li>
              </ul>
            </li>
            <li>Hobid ja vaba aeg
              <ul className={classes.spacedList}>
                <li>Räägi, mida sa teed vabal ajal.</li>
                <li>Milliseid hobisid sul veel on?</li>
              </ul>
            </li>
            <li>Varasem koolikogemus
              <ul className={classes.spacedList}>
                <li>Milline õpilane sa oled?</li>
                <li>Mis sa arvad, mis on ühe hea õpilase isikuomadused ja oskused?</li>
                <li>Kirjelda, millised grupitööd koolis tavaliselt välja nägid?</li>
                <li>Mis olid koolis su lemmik õppeained?</li>
                <li>Millised õppeained sulle koolis ei meeldinud?</li>
                <li>Kui palju õpilasi teil klassis oli? Mis sa arvad, kuidas sul on õppida klassis kus on 30 õpilast?</li>
              </ul>
            </li>
            <li>Varia
              <ul className={classes.spacedList}>
                <li>Mis sa siis teeksid kui sa siia kooli sisse ei saa?</li>
              </ul>
            </li>
          </ul>
          <h3>Hindamistingimused</h3>
          <strong>25 punkti</strong><br />
          <Text mb="xs">õpilaskandidaat on rahulik ja tähelepanelik, saab kõigist talle esitatud küsimustest aru ja vastab selgelt ja arusaadavalt, täislausetega. Õpilasel on huvi meie koolis õppida.</Text>
          <strong>20 punkti</strong><br />
          <Text mb="xs">õpilaskandidaat on tähelepanelik, saab kõigist talle esitatud küsimustest aru ja väljendab end napisõnaliselt. Komisjon peab esitama suunavaid ja täiendavaid küsimusi. Õpilasel on huvi meie koolis õppida.</Text>
          <strong>15 punkti</strong><br />
          <Text mb="xs">õpilaskandidaat on hajevil/rahutu, saab kõigist talle esitatud küsimustest aru, kuid tema vastustest ei selgu kindel huvi kooli/eriala vastu, vastused on umbmäärased.</Text>
          <strong>10 punkti</strong><br />
          <Text mb="xs">õpilaskandidaat on hajevil/rahutu, saab enamikust talle esitatud küsimustest aru, kuid tema vastustest ei selgu kindel huvi kooli/eriala vastu, vastused on umbmäärased.</Text>
          <strong>5 punkti</strong><br />
          <Text mb="xs">õpilaskandidaat on üleolev, käitub ebaviisakalt, saab vähestest küsimustest aru ning vastab neile</Text>
          <strong>0 punkti</strong><br />
          <Text mb="xs">õpilaskandidaat keeldub küsimustele vastamast või ei saa talle esitatud asjakohastest küsimustest aru. / õpilaskandidaadil on negatiivne hoiak kutseõppe, kooli, õppimise vms suhtes.</Text>
          <br />
          <br />
        </Card>
      </Grid.Col>
      <Grid.Col span={12}>
        <Card>
          <Stack spacing="xs">
            <Text fz="xs">Vestlusega alustati: {formatDate(currentTest.startedAt)}</Text>
            {hasEnded && <Text fz="xs">Vestlus lõpetati: {formatDate(currentTest.returnedAt)}</Text>}
            {hasEnded && <Text fz="xs">Vestluse kestvus: {formatStopwatch(currentTest.returnedAt)}</Text>}
            {currentTest?.gradedBy && <Text fz="xs">Vestluse protokollija: {currentTest.gradedBy}</Text>}
          </Stack>
        </Card>
      </Grid.Col>
        <Card style={{position: "fixed", bottom: "1rem", right: "1rem", fontSize: "2em", fontWeight: "bold"}}>
          {hasStarted && !hasEnded && <Text>{formatStopwatch()}</Text>}
        </Card>
    </Grid>
  )
}