import useSWR from 'swr'
import { Grid, Box, Button, Flex, Text, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { IconSearch, IconBook, IconDeviceDesktop, IconBrandWechat } from '@tabler/icons-react';
import { useAppStore } from "../../components/useAppStore"
import { useNavigate } from "react-router-dom"
import useBackendRequests from '../../components/useBackendRequests';
import useSession from '../../components/useSession';
import { BACKEND_API_URL } from '../../App';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';

export default function ListEntrants() {
  const { setHeaderTitle } = useAppStore();
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const searchInputRef = useRef({ value: "" });
  const { fetcher } = useBackendRequests();
  const { data } = useSWR((debouncedQuery ? `/entrants/search/${debouncedQuery}` : null), fetcher, { refreshInterval: 10000 });
  const navigate = useNavigate();
  const { isAdmin, accessToken } = useSession();
  const [entrants, setEntrants] = useState([]);
  const [selectedDay, setSelectedDay] = useState(dayjs());

  useEffect(() => {
    setHeaderTitle("Sisseastujad");
    return () => {
      setHeaderTitle("");
    }
  }, []);

  const downloadAlternativePrograms = () => {
    fetch(BACKEND_API_URL + '/entrants/alternative-program?csv=true', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `soovitatud-erialad.csv`
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again         
      })
      .catch(response => {
        if (!response?.errors && !response?.newRecords) {
          showNotification({
            title: 'Failed to export entrants',
            message: 'Unexpected error, see server logs',
            color: 'red',
            autoClose: 8000,
          });
        }

        if (response?.errors) {
          //setImportResults(response);
        }
      });
  }

  const listTodaysEntrants = () => {
    fetch(BACKEND_API_URL + `/entrants/today?date=${selectedDay.format("YYYY-MM-DD")}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(newEntrants => {
        setEntrants(newEntrants);
      })
      .catch(error => {
        console.error("Failed to fetch entrants:", error);
        // Handle the error, e.g., display an error message
      });
  }; 

  useEffect(() => {
    if (data) {
      setEntrants(data);
    }
  }, [data]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <Text fz="md" mb="xs">Sisesta kandideerija isikukood või otsi nime järgi</Text>
          <Flex mb="md" sx={{ gap: '1rem' }}>
            <TextInput
              ref={searchInputRef}
              sx={{ flex: 1 }}
              placeholder="Otsi kõikide kandidaatide hulgast"
              icon={<IconSearch size={22} />}
              value={query}
              onChange={(e) => setQuery(e.currentTarget.value)}
              size="md"
            />
            <Button variant="filled" size="md" ml="xs" onClick={(e) => setQuery(searchInputRef.current.value)}>
              Otsi
            </Button>
            <Button
              variant="filled"
              size="md"
              ml="xs"
              onClick={(e) => {
                e.preventDefault();
                setSelectedDay(prevState => prevState.subtract(1, 'day'))
              }}
            >
              &lt;
            </Button>
            <Button
              variant="filled"
              size="md"
              ml="0.1rem"
              style={{ width: '18rem' }}
              onClick={(e) => {
                e.preventDefault();
                listTodaysEntrants();
              }}
            >
              {selectedDay.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD") ? "Tänased sisseastujad" : selectedDay.format("ddd DD.MM")}
            </Button>
            <Button
              variant="filled"
              size="md"
              ml="0.1rem"
              onClick={(e) => {
                e.preventDefault();
                setSelectedDay(prevState => prevState.add(1, 'day'))
              }}
            >
              &gt;
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>

      <Box>
        <DataTable
          sx={{ minHeight: 300 }}
          withBorder
          highlightOnHover
          striped
          horizontalSpacing="sm"
          records={entrants}
          onRowClick={(entrant, rowIndex) => {
            navigate(`/entrants/${entrant.id}`);
          }}
          columns={[
            { accessor: 'name', render: ({ firstName, lastName }) => `${firstName} ${lastName}`, title: 'Nimi' },
            { accessor: 'pin', title: 'Isikukood' },
            { accessor: 'program', title: 'Eriala' },
            { accessor: 'appointmentDate', title: 'Katsete KP', render: ({appointmentDate}) => dayjs(appointmentDate).format("ddd DD.MM HH:mm") },
            { accessor: 'nextRoom', title: 'Järgmine ruum' },
            { accessor: 'totalScore', title: 'Punkte' },
            {
              accessor: 'EntrantTests', title: 'Lõpetatud testid', render: ({ id, EntrantTests }) => {
                const testsIcons = [IconDeviceDesktop, IconBook, IconBrandWechat];
                return testsIcons.reduce((acc, testIcon, index) => {
                  const test = EntrantTests.find(test => test.testId === index + 1);
                  const IconComponent = testsIcons[index % testsIcons.length];
                  const icon = <IconComponent color={test?.returnedAt ? "#000" : "#ccc"} style={{ margin: '0 3' }} />;
                  let score = test?.score ?? '?';
                  if (test?.testId === 2 && test?.gradedBy === null) {
                    score = null;
                  }
                  return [...acc, <span key={`${id}-${test?.id}-${index}`} style={{display: "inline-flex", alignItems: "center", whiteSpace: "pre", fontFamily: "monospace"}}>{icon} {score?.toString().padStart(2, ' ') ?? ' ?'}&nbsp;</span>];
                }, []);
              }
            }
          ]}
        />
      </Box>

      <Flex mb="md" mt="md" sx={{ justifyContent: 'flex-end', gap: '1rem' }}>
        <Button color="green.4" onClick={() => navigate(`/entrants/import`)}>Impordi sisseastujad (XLSX)</Button>
        <Button color="green.4" onClick={() => navigate(`/entrants/export`)}>Expordi tulemused (XML)</Button>
        <Button color="green.4" onClick={downloadAlternativePrograms}>Alternatiivse eriala soovitused (CSV)</Button>
      </Flex>
    </>
  );
}