import useSWR from 'swr'
import { Text, Box, Grid, SegmentedControl, TextInput, Notification } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useState } from 'react';
import { IconSearch } from '@tabler/icons-react';
import { useAppStore } from "../../components/useAppStore"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import useBackendRequests from '../../components/useBackendRequests';
import { useLocalStorage } from '@mantine/hooks';

export default function InterviewPoC() {
  const { setHeaderTitle } = useAppStore();
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const navigate = useNavigate();
  const { fetcher } = useBackendRequests();
  const [myRoom, setMyRoom] = useLocalStorage({ key: 'interviewTestRoom', defaultValue: '' });

  const { data: settings } = useSWR("/settings", fetcher, {
    fallbackData: {},
  });
  const interviewRooms = useMemo(() => settings?.interviewRooms?.split(",") ?? [], [settings]);
  const receptionAdministrator = settings?.receptionAdministrator ?? "";

  const { data: unsortedData } = useSWR((
    debouncedQuery ? (
      `/entrants/search/${debouncedQuery}`
    ) : (
      myRoom ? (
        `/entrants/next-room/${myRoom}?onlyTodays=true`
      ) : (
        null
      )
    )), fetcher, { refreshInterval: 10000 });

  // Cache some test data for sorting and data display
  const data = useMemo(() => {
    unsortedData?.forEach((row) => {
      row.thirdTest ??= row.EntrantTests.find(test => test.testId === 3);
      row.secondTest ??= row.EntrantTests.find(test => test.testId === 2);
      row.secondTestReturnedAt = row.secondTest?.returnedAt ? dayjs(row.secondTest.returnedAt).valueOf() : Number.MAX_SAFE_INTEGER;
      row.secondTestScore = row.secondTest?.score ?? "?";
      if (row.secondTest?.gradedBy === null) {
        row.secondTestScore = "?";
      }
    });
    unsortedData?.sort((a, b) => a.secondTestReturnedAt - b.secondTestReturnedAt);
    return unsortedData;
  }, [unsortedData]);

  useEffect(() => {
    setHeaderTitle("Vestlused");
    return () => {
      setHeaderTitle("");
    }
  }, [setHeaderTitle]);

  const missingEntrantText = receptionAdministrator ? `Eksinud kandidaat saada nõustamisele ${receptionAdministrator}` : "";

  return (
    <>
      <Grid align="center" mb="md">
        <Grid.Col xs={8} sm={9}>
        <Text fz="md" mb="xs">Sisesta kandideerija isikukood või otsi nime järgi</Text>
          <TextInput
            sx={{ flexBasis: '60%' }}
            placeholder="Otsi kõikide kandidaatide hulgast"
            icon={<IconSearch size={16} />}
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
            size='md'
          />
        </Grid.Col>
        <Grid.Col xs={4} sm={3}>
          Asun ruumis: <SegmentedControl
            data={interviewRooms}
            value={myRoom}
            onChange={setMyRoom}
          />
        </Grid.Col>
      </Grid>

      <Notification>
        Palun öelge uksel järgmise 5-6 õpilase nimed ja selgitaks, et nad ootaks siin, teised võivad vahepeal umbes tunnikese ära käia.
      </Notification>

      <h2>{debouncedQuery ? "Otsingutulemused kõikidest ruumidest" : `Sinu ruumi (${myRoom ? myRoom : "valimata"}) määratud sisseastujad`}</h2>
      <Box sx={{ height: 300 }}>
        <DataTable
          withBorder
          highlightOnHover
          records={data}
          noRecordsText={debouncedQuery ? `Otsingutulemusi ei leitud. ${missingEntrantText}` : "Siia ruumi pole veel kedagi suunatud"}
          onRowClick={(entrant, rowIndex) => {
            navigate(`/interview/${entrant.id}`);
          }}
          columns={[
            { accessor: 'name', render: ({ firstName, lastName }) => `${firstName} ${lastName}`, title: 'Nimi' },
            { accessor: 'program', title: 'Eriala' },
            { accessor: 'pin', title: 'Isikukood' },
            { accessor: 'nextRoom', title: 'Oodatav ruum' },
            {
              accessor: 'secondTestReturnedAt', title: 'Lõpetas vihikuga', render: ({ secondTest, secondTestScore }) => {
                return secondTest?.returnedAt ? dayjs(secondTest.returnedAt).format("dd DD.MM HH:mm")+` (${secondTestScore}p)` : "Pole lõpetanud";
              }
            },
            {
              accessor: 'thirdTest.startedAt',
              title: 'Vestlus alustatud',
              render: ({thirdTest}) => thirdTest?.startedAt ? dayjs(thirdTest.startedAt).format("dd DD.MM HH:mm") : "Pole alustanud"
            },
            {
              accessor: 'thirdTest.returnedAt',
              title: 'Vestlus lõpetatud',
              render: ({thirdTest}) => thirdTest?.returnedAt ? dayjs(thirdTest.returnedAt).format("dd DD.MM HH:mm") + ` (~${dayjs(thirdTest.returnedAt).diff(thirdTest.startedAt, 'minutes')} min)` : "Pole lõpetanud"
            }
          ]}
        />
      </Box>
    </>
  );
}