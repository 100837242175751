import { Group, Stack, Grid, Title, Center, UnstyledButton, Card, Text, createStyles } from "@mantine/core";
import { IconPencil, IconBook, IconDeviceDesktop, IconBrandWechat, IconChevronRight } from '@tabler/icons-react';
import { useAppStore } from "../../components/useAppStore"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const testItemsData = [
  { url: '/practical', title: 'Üldtestile registreerimine', icon: IconDeviceDesktop },
  { url: '/written', title: 'Erialatestile registreerimine', icon: IconBook },
  { url: '/interview', title: 'Vestluse hindamine', icon: IconBrandWechat },
  { url: '/written/grade', title: 'Erialatesti punktide sisestamine', icon: IconPencil },
];

const useStyles = createStyles((theme) => ({
  quickButton: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[8] : theme.colors.blue[0],
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[7] : theme.colors.blue[1],
    },
    color: theme.colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.blue[8],
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    width: '100%',
    height: '100%',
    lineHeight: 1.2,
  },
  inlineIcon: {
    height: '.7em',
    width: 'auto'
  }
}));

export default function TestsPoC(props) {
  const { setHeaderTitle } = useAppStore();
  const navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    setHeaderTitle("Testid");
    return () => {
      setHeaderTitle("");
    }
  }, []);

  const testItems = testItemsData.map((item) => (
    <Grid.Col md={4} lg={4} key={item.title}>
    <UnstyledButton key={item.title} onClick={() => navigate(item.url)} className={classes.quickButton} fullWidth>
      <Center mb="xs">
        <item.icon size={36} />
      </Center>
      <Text align="center" fw={600} size={18}>
        {item.title} <IconChevronRight strokeWidth={4} className={classes.inlineIcon} />
      </Text>
    </UnstyledButton>
    </Grid.Col>
  ));

  return <Grid justify="center" {...props}>
    <Grid.Col xs={12} md={12} lg={12}>
      <Card radius="sm">
        <Title align="center" mt="md" mb="lg" order={1}>Vali test, mida administreerid</Title>
        <Grid justify="center" {...props} grow>
          {testItems}
        </Grid>
      </Card>
    </Grid.Col>
    <Grid.Col xs={12} md={12} lg={12}>
      <Title align="center" mt="lg" mb="lg" order={1}>Sisseastumisvoorude administreerimise juhend</Title>
    </Grid.Col>
    <Grid.Col md={4} lg={4}>
      <Card shadow="sm" p="lg" radius="md" style={{ height: "100%" }}>
        <Stack justify="space-between" style={{ height: "100%" }}>
          <Group mt="md" mb="xs">
            <Text fz="lg" weight={700}>Test arvutis</Text>
            <Text size="sm" color="dimmed">
              Vali see kui hakkad registreerima sisseastujaid, kes tulevad tegema testi arvutis.
              Vajutades alusta avaneb vaade, kuhu saab sisestada sisseastuja isikukoodi.
              Sisseastuja kohalolek ja alustamise aeg registreeritakse süsteemis kohe pärast isikukoodi sisestamist.
            </Text>
          </Group>
        </Stack>
      </Card>
    </Grid.Col>
    <Grid.Col md={4} lg={4}>
      <Card shadow="sm" p="lg" radius="md" style={{ height: "100%" }}>
        <Stack justify="space-between" style={{ height: "100%" }}>
          <Group mt="md" mb="xs">
            <Text fz="lg" weight={700}>Kirjalik test</Text>
            <Text size="sm" color="dimmed">
              Vali see kui hakkad registreerima sisseastujaid, kes tulevad tegema kirjalikku vihiku testi.
              Vajutades alusta avaneb vaade, kuhu saab sisestada sisseastuja isikukoodi.
              Sisseastuja kohalolek ja alustamise aeg registreeritakse süsteemis kohe pärast isikukoodi sisestamist.
            </Text>
          </Group>
        </Stack>
      </Card>
    </Grid.Col>
    <Grid.Col md={4} lg={4} style={{ height: "100%" }}>
      <Card shadow="sm" p="lg" radius="md" span={4}>
        <Stack justify="space-between" style={{ height: "100%" }}>
          <Group mt="md" mb="xs">
            <Text fz="lg" weight={700}>Vestlus</Text>
            <Text size="sm" color="dimmed">
              Vali see kui hakkad läbi viima vestlusi.
              Vajutades alusta avaneb vaade, kus saab valida mis ruumis asud ning näed vestlusele saadetute nimekiri.
              Otsing on võimalik nii isikukoodi kui ka nime järgi ja vajadusel leiab ka teistesse ruumidesse saadetud õpilased.
              Sisseastuja kohalolek ja alustamise aeg registreeritakse süsteemis käsitsi.
            </Text>
          </Group>
        </Stack>
      </Card>
    </Grid.Col>
  </Grid>;
}