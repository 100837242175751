import {create} from "zustand"

const parseLocalStorage = (key, defaultValue) => {
  try {
    let item = window?.localStorage?.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } catch (e) {
    return defaultValue
  }
}

const setLocalStorage = (key, value) => {
  try {
    window?.localStorage?.setItem(key, JSON.stringify(value));
  } catch (e) {
    // Ignore
  }
}

const useSessionStore = create((set, get) => ({
  accessToken: parseLocalStorage("accessToken", null),
  userId: parseLocalStorage("userId", null),
  recentSessions: parseLocalStorage("recentSessions", {}),
  setAll: (data) => {
    let { accessToken, userId, recentSessions } = get();

    if (data.accessToken && data.accessToken !== accessToken) {
      setLocalStorage("accessToken", data.accessToken);
    }

    if (data.userId && data.userId !== userId) {
      setLocalStorage("userId", data.userId);
    }

    if (data.recentSessions && data.recentSessions !== recentSessions) {
      setLocalStorage("recentSessions", data.recentSessions);
    }

    set({ accessToken, userId, recentSessions, ...data });
  },
  setAccessToken: (accessToken) => {
    if (typeof accessToken === "function") {
      accessToken = accessToken(get().accessToken);
    }
    setLocalStorage("accessToken", accessToken);
    set({ accessToken });
  },
  setUserId: (userId) => {
    if (typeof userId === "function") {
      userId = userId(get().userId);
    }
    setLocalStorage("userId", userId);
    set({ userId });
  },
  setRecentSessions: (recentSessions) => {
    if (typeof recentSessions === "function") {
      recentSessions = recentSessions(get().recentSessions);
    }
    setLocalStorage("recentSessions", recentSessions);
    set({ recentSessions });
  }
}));

export default useSessionStore;