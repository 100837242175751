import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { MediaQuery, Theme, Flex, Container, Grid, Title, Button, Center, Card, TextInput, Group } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import useSession from "../../components/useSession"
import { showNotification } from "@mantine/notifications"
import { useForm } from "@mantine/form"
import { postJson } from "../../components/networking"
import { BACKEND_API_URL } from '../../App';
import { unstable_batchedUpdates } from "react-dom"
import Brand from '../../components/brand.js';
import { useMantineTheme } from '@mantine/core';

export default function Login() {
	const { currentUser, isLoading, login } = useSession({ redirect: false })
	const isLoggedIn = currentUser && currentUser.id && currentUser.status !== 401
	const navigate = useNavigate()
	const searchParams = new URLSearchParams(window.location.search);
	const [codeIsSent, setCodeIsSent] = useState(false);
	const theme = useMantineTheme();

	useEffect(() => {
		document.body.classList.add('login');
		document.body.classList.add(theme.colorScheme);

		// Used to show session timeout message
		if (!isLoading && !isLoggedIn && searchParams.get("error")) {
			showNotification({
				message: searchParams.get("error"),
				color: 'red',
				autoClose: 5000,
			})
		}

		// If already logged in redirect to the last page or dashboard
		if (isLoggedIn) {
			navigate(searchParams.get("lastPage") || "/")
		}
	}, [isLoggedIn, isLoading])

	const form = useForm({
		initialValues: {
			email: searchParams.get("email") ? searchParams.get("email") : "",
			password: searchParams.get("otp") ? searchParams.get("otp") : "",
		},

		validate: {
			email: (value) => (value.length < 2 ? 'Palun sisesta oma e-posti aadress' : null),
			password: (value) => (codeIsSent && value.length < 1 ? 'Sisesta ühekordne kood mis saadeti sulle e-postiga' : null),
			//email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
		},
	});

	const handleResendCode = () => {
		unstable_batchedUpdates(() => {
			setCodeIsSent(false)
			form.setFieldValue("password", "")
			form.validateField('email')
			if (form.isValid('email')) {
				handleSubmit({ email: form.values.email, password: "" })
			}
		})
	}

	const handleSubmit = (values) => {
		if (values.email && values.password.length === 0) {
			postJson(BACKEND_API_URL + "/login", { email: values.email }).then((response) => {
				setCodeIsSent(true)
				showNotification({
					message: response.message,
					autoClose: 5000,
				})
			}).catch((err) => {
				showNotification({
					title: "Parooli saatmine ebaõnnestus",
					message: err.message,
					color: 'red',
					autoClose: 6000,
				})
			})
			return;
		}

		login(values)
	}

	useEffect(() => {
		// if both email and password are filled using otp search query param then submit the form
		if (form.values.email && form.values.password.length > 0 && searchParams.get("otp")?.length > 0) {
			handleSubmit({ email: form.values.email, password: form.values.password })
		}
	}, [form.values.email, form.values.password])

	return (
		<Container size="md">
			<MediaQuery query="(min-width: 687px)" styles={(theme) => ({ height: "100vh" })}>
				<Grid grow justify="center" align="center">
					<Grid.Col span={6} mt="lg">
						<Center>
							<Brand size={300} />
						</Center>
					</Grid.Col>
					<Grid.Col span={6}>
						<Card shadow="sm" p="lg" radius="xl">
							<Title ta="center" mb="sm" color="blue.8" order={1}>Logi sisse</Title>
							<form onSubmit={form.onSubmit(handleSubmit)}>
								{/* input type? */}
								<TextInput
									size="md"
									label="E-post"
									type="email"
									placeholder="eesnimi.perenimi@email.com"
									{...form.getInputProps('email')}
								/>
								{codeIsSent && (
									<TextInput
										size="md"
										label="Ühekordne kood"
										placeholder="123456"
										{...form.getInputProps('password')}
									/>
								)}
								<Group position="center" mt="xs">
									{codeIsSent && <Button onClick={handleResendCode}>Saada uus kood</Button>}
									<Button fullWidth type="submit" size='md' radius="md">Jätka <IconChevronRight size={16} strokeWidth={4} /> </Button>
								</Group>
							</form>
						</Card>
					</Grid.Col>
				</Grid>
			</MediaQuery>
		</Container>
	)
}