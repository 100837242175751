import useSessionStore from "./useSessionStore";
import { BACKEND_API_URL } from "../App";
import { fetchData } from "./networking";
import { useCallback } from "react";

export default function useBackendRequests(props = {}) {
    const { accessToken } = useSessionStore()
    const { dataAccessor, errorAccessor } = props
    return useCallback({
        getJson: (url, signal) => {
            return fetchData(BACKEND_API_URL+url, "GET", null, {
                options: { signal },
                headers: { authorization: `Bearer ${accessToken}` },
                dataAccessor,
                errorAccessor
            })
        },
        postJson: (url, data, signal) => {
            return fetchData(BACKEND_API_URL+url, "POST", data, {
                options: { signal },
                headers: { authorization: `Bearer ${accessToken}` },
                dataAccessor,
                errorAccessor
            })
        },
        putJson: (url, data, signal) => {
            return fetchData(BACKEND_API_URL+url, "PUT", data, {
                options: { signal },
                headers: { authorization: `Bearer ${accessToken}` },
                dataAccessor,
                errorAccessor
            })
        },
        deleteJson: (url, data, signal) => {
            return fetchData(BACKEND_API_URL+url, "DELETE", null, {
                options: { signal },
                headers: { authorization: `Bearer ${accessToken}` },
                dataAccessor,
                errorAccessor
            })
        },
        fetcher: (url, method, data, options) => {
            return fetchData(BACKEND_API_URL+url, method, data, {
                ...options,
                headers: { authorization: `Bearer ${accessToken}` },
                dataAccessor,
                errorAccessor
            })
        }
    }, [accessToken, dataAccessor, errorAccessor])
}