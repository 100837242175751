import {useCallback, useEffect, useState} from "react"
import {fetchWithToken, postJson} from "./networking"
import {useNavigate} from "react-router-dom"
import {produce} from "immer"
import {unstable_batchedUpdates} from "react-dom"
import useSWR from "swr"
import useSessionStore from "./useSessionStore"
import {showNotification} from "@mantine/notifications"
import {BACKEND_API_URL} from "../App"

const getCurrentPage = () => {
	let lastPage = window.location.pathname
	if (window.location.query)
		lastPage += "?" + window.location.query
	return lastPage
}

/**
 *
 * @returns {{currentUser, logout: logout, accessToken: *, login: login, userId: *, recentSessions: object, switchUser: switchUser}}
 */
export default function useSession() {
	const {accessToken, setAccessToken, userId, setUserId, recentSessions, setRecentSessions} = useSessionStore()
	const lastUserSession = recentSessions[userId] || null
	const [printedIntoConsole, setPrintedIntoConsole] = useState(false)
	const navigate = useNavigate()

	// Get the current user
	const {data: currentUser, isLoading: isSWRLoading, isValidating} = useSWR(
		[`${BACKEND_API_URL}/user-profile`, accessToken],
		fetchWithToken
	)
	const isLoading = isSWRLoading || isValidating
	// Fake data for testing
	//const currentUser = accessToken ? {id: 1, name: "John Doe", email: "john.doe@tptlive.ee", status: 200} : null
	//const isLoading = false

	// Redirect to the login page if session is expired or user is not logged in
	useEffect(() => {
		const currentPage = getCurrentPage()
		const isLoggedOut = !accessToken || !userId || !currentUser || !currentUser.id || currentUser.status === 401
		if (!isLoading && isLoggedOut && !currentPage.startsWith("/login")) {
			let searchParams = new URLSearchParams()
			searchParams.append('lastPage', currentPage)
			if (lastUserSession)
				searchParams.append('email', lastUserSession.email)
			if (accessToken && currentUser)
				searchParams.append('error', "Sessioon on aegunud, logi uuesti sisse")

			const searchParamsStr = searchParams.toString()
			navigate("/login" + (searchParamsStr ? "?" + searchParamsStr : ""), {replace: true})
		}
	}, [currentUser, isLoading])

	// Save user profile into recentSessions when logging in
	/*useEffect(() => {
		if (currentUser && userId && (!recentSessions[userId] || recentSessions[userId].accessToken !== accessToken)) {
			setRecentSessions((prevState) => {
				return produce(prevState, (draft) => {
					draft[userId] = currentUser
					draft[userId].accessToken = accessToken
				})
			})
		}
	}, [currentUser, userId, accessToken])*/

	useEffect(() => {
		if (!printedIntoConsole && accessToken && userId && currentUser && currentUser.id) {
			let firstName = currentUser.email.split(".")[0];
			firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
			console.log(firstName + " mis teed siin 😁 ära palun live'is midagi torkima hakka. GitHubis on kood, mölla localis 😊")
			setPrintedIntoConsole(true)
		}
	}, [accessToken, userId, currentUser])

	const login = useCallback((values) => {
		postJson(BACKEND_API_URL + "/login", {
			"email": values.email.trim(),
			"password": values.password,
			//"validForSeconds": values.keepSessionForSeconds ?? 604800, // 604800 is 7 days in seconds
			//"cookie": true
		}).then((response) => {
			unstable_batchedUpdates(() => {
				setAccessToken(response.accessToken)
				setUserId(response.claims.sub)
				// Update access token when recent session token was expired
				/*if (recentSessions[response.claims.sub]) {
					setRecentSessions((prevState) => {
						return produce(prevState, (draft) => {
							draft[response.claims.sub].accessToken = response.accessToken
						})
					})
				}*/
			})
		}).catch((response) => {
			console.log("error res", response)
			if (response) {
				let message = "Unknown error"
				if (typeof response === "string")
					message = response
				if (response instanceof Array)
					message = response.join("\n")
				if (response.hasOwnProperty('message'))
					message = response.message
				if (response.hasOwnProperty('error'))
					message = response.error

				showNotification({
					title: 'Sisselogimine ebaõnnestus',
					message: message,
					color: 'red',
					autoClose: 8000,
				})
			}
		})
	}, [setAccessToken, setUserId])

	const logout = useCallback(() => {
		// Fake logout
		unstable_batchedUpdates(() => {
			// Clear from session history
			if (recentSessions[userId]) {
				setRecentSessions((prevState) => {
					return produce(prevState, (draft) => {
						draft[userId].accessToken = ""
					})
				})
			}

			// Clear local storage
			setAccessToken(null)
			setUserId(null)
		})
		/* invalidate is not supported on this backend
		fetchData(BACKEND_API_URL + `/v1/auth/invalidate`, "POST", {token: accessToken}, {
			headers: {authorization: `Bearer ${accessToken}`},
		}).finally(() => {
			unstable_batchedUpdates(() => {
				// Clear from session history
				if (recentSessions[userId]) {
					setRecentSessions((prevState) => {
						return produce(prevState, (draft) => {
							draft[userId].accessToken = ""
						})
					})
				}

				// Clear local storage
				setAccessToken(null)
				setUserId(null)
			})

			if (callback) {
				callback()
			}
		})*/
	}, [accessToken, setAccessToken, setUserId])

	/**
	 * Switch user
	 * @param {string} switchToUserId `0` will go to the login screen, but keeps the current session in history
	 * @type {(function(*): void)|*}
	 */
	const switchUser = useCallback((switchToUserId) => {
		if (userId === switchToUserId)
			return;

		// noinspection EqualityComparisonWithCoercionJS
		if (switchToUserId == 0) {
			unstable_batchedUpdates(() => {
				setAccessToken(null)
				setUserId(null)
			})
			return;
		}

		const user = recentSessions[switchToUserId]
		if (user) {
			unstable_batchedUpdates(() => {
				setAccessToken(user.accessToken)
				setUserId(switchToUserId)
			})
		}
	}, [recentSessions, setAccessToken, userId, setUserId])

	const removeRecentSession = useCallback((userId) => {
		setRecentSessions((prevState) => {
			return produce(prevState, (draft) => {
				delete draft[userId]
			})
		})
	}, [setRecentSessions])

	/**
	 * @param {string} permission
	 * @return {boolean} true if user has permission
	 */
	const canUser = useCallback((permission) => {
		if (!currentUser || !currentUser.permissions)
			return false;
		return currentUser.permissions.indexOf(permission) !== -1
	}, [currentUser, userId]);

	return {currentUser, canUser, userId, isLoading, accessToken, login, logout, recentSessions, switchUser, removeRecentSession, isAdmin: currentUser?.isAdmin ?? false}
}