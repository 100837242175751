import {create} from "zustand"

export const useAppStore = create((set, get) => ({
  menuOpen: false,
  toggleMenuOpen: () => set({ menuOpen: !get().menuOpen }),
  setMenuOpen: (menuOpen) => set({ menuOpen }),

  headerTitle: "",
  setHeaderTitle: (headerTitle) => set({ headerTitle }),

  headerActions: {},
  sortedHeaderActions: [],
  addHeaderAction: (action) => {
    const newHeaderActions = { ...get().headerActions, [action.name]: action }
    set({
      headerActions: newHeaderActions,
      sortedHeaderActions: Object.entries(newHeaderActions).sort((a, b) => (a[1].order ?? 1) - (b[1].order ?? 1))
    })
  },
  removeHeaderAction: (name) => {
    const { [name]: _, ...headerActions } = get().headerActions
    set({
      headerActions,
      sortedHeaderActions: Object.entries(headerActions).sort((a, b) => (a[1].order ?? 1) - (b[1].order ?? 1))
    })
  },
}))