import { useEffect, useState } from 'react';
import { useAppStore } from "../../components/useAppStore"
import useBackendRequests from '../../components/useBackendRequests';
import { showNotification } from '@mantine/notifications';
import { Card, Button, Group } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { BACKEND_API_URL } from '../../App';

export default function ExportEntrants() {
    const { setHeaderTitle } = useAppStore();
    const { fetcher } = useBackendRequests();
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);

    useEffect(() => {
        setHeaderTitle("Sisseastujate eksport");
        return () => {
            setHeaderTitle("");
        }
    }, []);

    const exportEntrants = (format) => {
        const accessToken = localStorage.getItem('accessToken');
        let start = 1;
        let end = 3;
        if (format === 'text/csv') {
            start = 0;
            end = 0;
        }
        for (let i = start; i <= end; i++) {
            let fileTestName = "koik";
            switch (i) {
                case 1:
                    fileTestName = "yldtest";
                    break;
                case 2:
                    fileTestName = "erialatest";
                    break;
                case 3:
                    fileTestName = "vestlus";
                    break;
                default:
                    fileTestName = "koik";
                    break;
            }
            let query = '';
            if (i > 0) {
                query = '?testId=' + i;
            }
            fetch(BACKEND_API_URL + '/entrants/export/sais'+query, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken.substring(1, accessToken.length - 1)}`,
                    'Content-Type': 'application/json',
                    'Accept': format,
                },
                body: JSON.stringify({
                    dateRange: dateRange.map(d => dayjs(d).format("YYYY-MM-DDTHH:mm:ss"))
                }),
            })
                .then(response => {
                    const entrantsCount = response.headers.get('x-entrants-count') || response.headers.get('X-Entrants-Count');
                    showNotification({
                        message: `${entrantsCount} entrants exported`,
                        color: 'green',
                        autoClose: 5000,
                    });
                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    const fromDay = dayjs(dateRange[0]).format("ddDD");
                    const toDay = dayjs(dateRange[1]).format("ddDD");
                    if (fromDay === toDay) {
                        a.download = `sais-export-${fromDay}-${fileTestName}.${format.split('/')?.[1] ?? "json"}`
                    } else {
                        a.download = `sais-export-${fromDay}-${toDay}-${fileTestName}.${format.split('/')?.[1] ?? "json"}`
                    }
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again         
                })
                .catch(response => {
                    if (!response?.errors && !response?.newRecords) {
                        showNotification({
                            title: 'Failed to export entrants',
                            message: 'Unexpected error, see server logs',
                            color: 'red',
                            autoClose: 8000,
                        });
                    }

                    if (response?.errors) {
                        //setImportResults(response);
                    }
                });
        }
    }

    return <Card style={{ minHeight: "30rem" /* kalendri avamise pärast on 30rem */ }}>
        <Group style={{ alignItems: "flex-end" }}>
            <DatePickerInput
                type="range"
                allowSingleDateInRange
                label="Ekspordi sisseastujad kelle vestlus oli vahemikus"
                placeholder="Vali kuupäevad"
                value={dateRange}
                onChange={setDateRange}
            />
            <Button onClick={exportEntrants.bind(null, 'text/xml')}>Ekspordi XML</Button>
            <Button onClick={exportEntrants.bind(null, 'text/csv')}>Ekspordi CSV</Button>
        </Group>
    </Card>
}