import { showNotification } from '@mantine/notifications'

export const jsonFetcher = (...args) => fetch(...args).then(res => res.json())
export const fetchWithToken = ([url, token]) => fetch(url, {
    headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
    }
}).then(res => res.json())


/** @type FetchAccessor */
const bodyOnlyAccessor = ({body}) => body;
/** @type FetchAccessor */
const bodyAndHeadersAccessor = ({body, response}) => ({body, headers: getHeaders(response)});
/** @type FetchAccessor */
const passAllAccessor = (all) => (all);
/** @type FetchAccessor */
const messageOnlyAccessor = ({message="Unknown error"}) => (message);

export const FetchAccessors = {
    bodyOnlyAccessor,
    bodyAndHeadersAccessor,
    passAllAccessor,
    messageOnlyAccessor
}

const getBackendResponseHandler = (resolve, reject, options, dataAccessorFn=bodyOnlyAccessor, errorAccessorFn=bodyOnlyAccessor) => {
    return (response) => {
        if (response.status === 204)
            return resolve(dataAccessorFn({body: undefined, response}));

        // Check if response is in JSON
        if (response.headers.get("Content-Type").includes("application/json")) {
            response.json().then(body => {
                // RESTful response without envelope
                // Success
                if (response.status < 400)
                    return resolve(dataAccessorFn({body, response}));

                // Failure
                // Handle API error special cases
                if (body.hasOwnProperty('message')) {
                    if (response.status >= 500) {
                        showNotification({
                            message: "Serveris tekkis viga",
                            color: 'red',
                            autoClose: false,
                        })
                    }
                    console.log(body);
                }
                reject(errorAccessorFn({body, rawError: null, response}));
            }).catch(rawError => {
                console.log("Error parsing JSON: " + (rawError.message || rawError) + ")")
                reject(errorAccessorFn({message: "Error parsing JSON: " + (rawError.message || rawError), rawError, response}));
            });
        } else {
            // Reject with error when we requested JSON but did not get. Otherwise, show raw body
            if (options.headers["Accept"] === "application/json") {
                reject(errorAccessorFn({message: "Server did not respond with JSON", rawError: null, response}));
            } else {
                response.text().then(body => {
                    resolve(dataAccessorFn({body, response}));
                });
            }
        }
    }
}

/**
 * Converts fetch headers to regular object
 * @param response
 * @returns {{}}
 */
function getHeaders(response) {
    let headers = {}
    for (let pair of response.headers) {
        headers[pair[0]] = pair[1]
    }
    return headers
}

/**
 * You can filter data in this type using accessor functions
 * @see FetchAccessor
 * @typedef {object} FetchSuccess
 * @param {*} body from server
 * @param {Response} response
 */

/**
 * You can filter data in this type using accessor functions
 * @see FetchAccessor
 * @typedef {object} FetchError
 * @param {string} [message] error data from server or local message
 * @param {*|null} [rawError] raw error from catch, if applicable
 * @param {Response} [response] fetch response, if applicable
 */

/**
 * Accessor function which filters data from FetchSuccess or FetchError
 * @typedef {object} FetchAccessor
 * @param {FetchSuccess|FetchError}
 * @see FetchAccessors
 */

/**
 * @return {Promise<FetchSuccess, FetchError>}
 * @param {string} url
 * @param {string} method
 * @param {*} data
 * @param {{options?: {signal?: AbortSignal}, headers?: Object.<string, string>, dataAccessor?, errorAccessor?}} config
 */
export function fetchData(url = "", method = "GET", data = null, config = {}) {
    return new Promise(function (resolve, reject) {
        let options = {
            method,
            headers: { "Accept": "application/json" },
            redirect: "follow",
            //credentials: "same-origin", //if using API gateway
            credentials: "include", //if there is no API gateway, TODO only when url matches backend
        };

        //options.headers[yiiOptions.csrfHeaderParam] = yiiOptions.csrfToken;

        if (typeof data !== "undefined" || data !== null) {
            if (data instanceof FormData) {
                options.body = data;
            } else if (data instanceof URLSearchParams) {
                options.headers["Content-Type"] = "x-www-form-urlencoded";
                options.body = data;
            } else {
                options.headers["Content-Type"] = "application/json";
                options.body = JSON.stringify(data);
            }
        }

        if (options.hasOwnProperty('body') && (options.method === "GET" || options.method === "HEAD" || options.method === "DELETE"))
            delete options.body;

        // Override headers included in config
        if (config.headers) {
            for (let option in config.headers) {
               /*  if (typeof config.headers[option] === "undefined" && options.headers.hasOwnProperty(option))
                    delete options.headers[option];
                else */
                    options.headers[option] = config.headers[option];
            }
        }

        // Override fetch options included in config
        if (config.options) {
            for (let option in config.options) {
               /*  if (typeof config.options[option] === "undefined" && options.hasOwnProperty(option))
                    delete options[option];
                else */
                    options[option] = config.options[option];
            }
        }

        fetch(url, options)
            .then(getBackendResponseHandler(resolve, reject, options, config.dataAccessor, config.errorAccessor))
            .catch(rawError => {
                let message = (rawError.message || rawError)
                console.log("Error fetching data: " + message, rawError);
                if (message.includes("fetch")) {
                    message = "Serveriga ei saadud ühendust"
                }
                reject({message, rawError})
            });
    });
}

//#region Generic helper functions for JSON data
/**
 * Makes JSON request, does NOT include authorization token.
 * @param {string} url
 * @param {AbortSignal} [signal]
 * @return {Promise<FetchSuccess, FetchError>}
 */
export function getJson(url, signal) {
    return fetchData(url, "GET", null, {options: { signal }});
}

/**
 * Makes JSON request, does NOT include authorization token.
 * @param {string} url
 * @param {*} [data]
 * @param {AbortSignal} [signal]
 * @return {Promise<FetchSuccess, FetchError>}
 */
export function postJson(url, data, signal) {
    return fetchData(url, "POST", data, {options: { signal }});
}

/**
 * Makes JSON request, does NOT include authorization token.
 * @param {string} url
 * @param {*} [data]
 * @param {AbortSignal} [signal]
 * @return {Promise<FetchSuccess, FetchError>}
 */
export function putJson(url, data, signal) {
    return fetchData(url, "PUT", data, {options: { signal }});
}

/**
 * Makes JSON request, does NOT include authorization token.
 * @param {string} url
 * @param {AbortSignal} [signal]
 * @return {Promise<FetchSuccess, FetchError>}
 */
export function deleteJson(url, signal) {
    return fetchData(url, "DELETE", null, {options: { signal }});
}
//#endregion