import { Card, SimpleGrid, createStyles, Text, Title, Badge } from "@mantine/core";
import { useAppStore } from "../../components/useAppStore"
import { useEffect, useMemo } from "react"
import useSWR from "swr"
import TestsPoC from "../tests/TestsPoC"
import useBackendRequests from "../../components/useBackendRequests";

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.green[9] : theme.colors.green[1],
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  count: {
    color: theme.colorScheme === 'dark' ? theme.colors.green[1] : theme.colors.green[9],
    fontSize: 46,
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: theme.spacing.sm,
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.colors.green[1] : theme.colors.green[8],
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
  },

  description: {
    color: theme.colorScheme === 'dark' ? theme.colors.green[0] : theme.colors.green[9],
    fontSize: theme.fontSizes.sm,
    marginTop: 5,
  },

  stat: {
    flex: 1,
    '& + &': {
      paddingLeft: theme.spacing.md,
      marginLeft: theme.spacing.md,
      borderLeft: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.green[8] : theme.colors.green[2]}`,
      [theme.fn.smallerThan('sm')]: {
        paddingLeft: 0,
        marginLeft: 0,
        borderLeft: 0,
        paddingTop: theme.spacing.md,
        marginTop: theme.spacing.md,
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.green[8] : theme.colors.green[2]}`,
      },
    },
  },
}));

/*
First we try to match letters before "-", when no match we remove all numbers and try full text match
Eg:
a) TA-22V -> TA (match)
b) TT-22E -> TT (no match), TT-E (match)
*/
const codeToProgramMap = {
  EA: "Sisetööde elektrik",
  EV: "Sisetööde elektrik",
  KEE: "Sisetööde elektrik",
  KEV: "Sisetööde elektrik",
  KSE5: "Sisetööde elektrik tase 5",
  TJE: "Jaotusvõrgu elektrik",
  KJE5: "Jaotusvõrgu elektrik tase 5",
  AA: "Automaatik",
  AV: "Automaatik",
  IT: "IT süsteemide spetsialist",
  KIT: "IT süsteemide spetsialist",
  TA: "Tarkvaraarendaja",
  KTA: "Tarkvaraarendaja",
  SA: "Telekommunikatsiooni nooremspetsialist",
  LA: "Elektroonikaseadmete tehnik",
  "TT-E": "Trükitehnoloogia (trükiste ettevalmistaja)",
  "KTS-E": "Trükitehnoloogia (trükiste ettevalmistaja)",
  "TT-T": "Trükitehnoloogia (ofsettrükkal)",
  "KTS-T": "Trükitehnoloogia (ofsettrükkal)",
  MM: "Multimeedia spetsialist",
  KMS: "Multimeedia spetsialist",
  VM: "Visuaalmeedia spetsialist",
  KLT: "Lavavalgustehnik",
  FS: "Fotograaf",
  KTO: "Teleoperaator",
}

// Avalik info > Laekunud avaldused (Vb see vana link: https://admin.sais.ee/PublicInfo/ApplicationStats?iid=991)
// Firefoxiga saab Ctrl või Alt drag kopeerida tabeli veergusid kopeerimiseks. Multiline selectiga Ctrl + Nooled aitab rühma koodi kätte saada.
const acceptedCount = {
  "EA-24B": 30,
  "AA-24A": 30,
  "SA-24": 30,
  "TT-24E": 15,
  "TT-24T": 15,
  "MM-24": 30,
  "TA-24A": 30,
  "AA-24B": 30,
  "TA-24B": 30,
  "IT-24A": 30,
  "EA-24A": 30,
  "VM-24": 30,
  "IT-24B": 30,
  "KTA-24": 30,
  "FS-24": 30,
  "KIT-24": 30,
  "KTS-24": 2,
  "KLT-24": 20,
  "KEE-24": 30,
}

export default function Dashboard() {
  const { classes } = useStyles();
  const { setHeaderTitle } = useAppStore();
  const { fetcher } = useBackendRequests();
  const { data, error } = useSWR("/statistics", fetcher);
  const currentPeriod = new Date().getMonth() >= 8 ? "fall" : "spring";

  function codeToProgram(programCode) {
    // split by "-" and take first part
    const program = codeToProgramMap[programCode.split('-')[0]];
    if (program) {
      return program;
    } else {
      const programCodeWithoutNumbers = programCode.replace(/\d/g, '');
      return codeToProgramMap[programCodeWithoutNumbers];
    }
  }

  const programFilters = {
    fall: (item) => item.program.startsWith("K") || item.program.startsWith("F"),
    spring: (item) => !item.program.startsWith("K") && !item.program.startsWith("F"),
  }

  const entrantsByProgramCountPK = useMemo(() => {
    if (data?.entrantsByProgram) {
      return data.entrantsByProgram.reduce((total, item) => {
        if (!programFilters[currentPeriod]) {
          return total;
        }
        return total + item._count;
      }, 0);
    }
    return 0;
  }, [data?.entrantsByProgram]);

  // group program codes by program name
  const reducer = (acc, entrant) => {
    const program = codeToProgram(entrant.program);
    if (!acc[program]) {
      acc[program] = { name: program, total: entrant._count, accepted: acceptedCount[entrant.program], groups: [entrant] };
    } else {
      acc[program].total += entrant._count;
      acc[program].accepted += acceptedCount[entrant.program];
      acc[program].groups.push(entrant);
    }
    return acc;
  };
  const programNamesSpring = useMemo(() => {
    let result = data?.entrantsByProgram.filter(programFilters.spring).reduce(reducer, {})
    if (!result) {
      return [];
    }
    return Object.values(result);
  }, [data?.entrantsByProgram]);
  const programNamesFall = useMemo(() => {
    let result = data?.entrantsByProgram.filter(programFilters.fall).reduce(reducer, {})
    if (!result) {
      return [];
    }
    return Object.values(result);
  }, [data?.entrantsByProgram]);
  // order program names based on counts
  const programNamesSortedSpring = useMemo(() => programNamesSpring.sort((a, b) => b.total - a.total), [programNamesSpring]);
  const programNamesSortedFall = useMemo(() => programNamesFall.sort((a, b) => b.total - a.total), [programNamesFall]);

  useEffect(() => {
    setHeaderTitle("Ülevaade");
    return () => {
      setHeaderTitle("");
    }
  }, [setHeaderTitle]);

  return <div>
    <Title align="center" mt="lg" mb="lg" order={1}>Statistika</Title>
    <div className={classes.root}>
      <div className={classes.stat}>
        <Text className={classes.count}>{entrantsByProgramCountPK}</Text>
        <Text className={classes.title}>avaldust on {currentPeriod === "fall" ? "keskhariduse baasil" : "kutsekeskharidusõppesse"}</Text>
        <Text className={classes.description}>{data?.entrants ?? "?"} avaldust on kokku põhikooli ja keskkooli baasil.</Text>
      </div>
      <div className={classes.stat}>
        <Text className={classes.count}>{data?.appointmentsToday ?? "?"}</Text>
        <Text className={classes.title}>sisseastujat on täna</Text>
        <Text className={classes.description}>
          Üldtesti lõpetanud: {data?.firstTestDone ?? "?"}<br/>
          Vihikuga lõpetanud: {data?.secondTestDone ?? "?"}<br/>
          Vihikuid hinnatud: {data?.secondTestGraded ?? "?"}<br/>
          Vestlusega lõpetanud: {data?.thirdTestDone ?? "?"}
        </Text>
      </div>
      <div className={classes.stat}>
        <Text className={classes.count}>{data?.entrantsWithAllTestsDone ?? "?"}</Text>
        <Text className={classes.title}>sisseastujal on kõik testid tehtud.</Text>
        {/* how many % of entrants have tests done */}
        <Text className={classes.description}>
          Kõikidest sisseastujatest {data?.entrantsWithAllTestsDone ? Math.round(data?.entrantsWithAllTestsDone / data?.entrants * 100) : "?"}%-l on testid tehtud.
        </Text>
      </div>
    </div>
    <Card padding="md" mt="lg" mb="lg" radius="md">
      <Title align="center" mt="lg" mb="sm" order={2}>Kutsekeskharidusõppesse sissestujate arv erialade kaupa</Title>
      <Text fz="sm" mb="xl" ta="center">*B/V: eestikeelse õppetöö maht &gt;60% õa jooksul</Text>
      <SimpleGrid cols={4} spacing="lg">
        {programNamesSortedSpring.map((program) => {
          return (
            <div key={program.name}>
              <Text fw={700}>{program.name}<Badge color="gray" radius="sm" ml="0.2rem" px="xs" variant="filled">{(program.total / (program.accepted / program.groups.length)).toFixed(2)}</Badge></Text>
              <Text fz="lg">{program.total}</Text>
              <div>
                {program.groups.map((group) => (
                  <Text key={group.program}>{group.program} <b>{group._count}</b> ({(group._count / acceptedCount[group.program]).toFixed(2)})</Text>
                ))}
              </div>
            </div>
          );
        })}
      </SimpleGrid>
    </Card>
    <Card padding="md" mt="lg" mb="lg" radius="md">
      <Title align="center" mt="lg" mb="sm" order={2}>Keskhariduse baasil kutseõppesse sisseastujate arv erialade kaupa</Title>
      <Text fz="sm" mb="xl" ta="center">Sessioonõppe ja fotograafi erialade testid toimuvad olenevalt osakonnast augustis/septembris</Text>
      <SimpleGrid cols={3} spacing="lg">
        {programNamesSortedFall.map((program) => {
          return (
            <div key={program.name}>
              <Text fw={700}>{program.name}<Badge color="gray" radius="sm" ml="0.2rem" px="xs" variant="filled">{(program.total / (program.accepted / program.groups.length)).toFixed(2)}</Badge></Text>
              <Text fz="lg">{program.total}</Text>
              <div>
                {program.groups.map((group) => (
                  <Text key={group.program}>{group.program} <b>{group._count}</b> ({(group._count / acceptedCount[group.program]).toFixed(2)})</Text>
                ))}
              </div>
            </div>
          );
        })}
      </SimpleGrid>
    </Card>
    <TestsPoC mt="lg" />
  </div>;
}