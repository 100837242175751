import { Button, Card, Flex, Modal, Table, Textarea, Tooltip, createStyles } from "@mantine/core";
import useBackendRequests from "../../components/useBackendRequests";
import useSWR from "swr";
import { IconCopy, IconDownload, IconSend, IconTrash } from "@tabler/icons-react";
import useSession from "../../components/useSession";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";

// mantine 6 styles
const useStyles = createStyles((theme, _params, getRef) => ({
    button: {
        '&:hover': {
            backgroundColor: theme.colors.blue[0],
            cursor: 'pointer',
        },
    }
}));

export default function InvalidTest1Responses() {
    const { fetcher, postJson, deleteJson } = useBackendRequests();
    const { data, mutate } = useSWR("/entrant-tests/invalid-test-1/responses", fetcher, {
        fallbackData: [],
    });
    const { isAdmin } = useSession({ redirect: false })
    const { classes } = useStyles();
    const [currentItem, setCurrentItem] = useState(null);

    return (
        <Card>
            <h1>Vigased esimese testi vastused</h1>
            <Table>
                <thead>
                    <tr>
                        <th>Fail</th>
                        <th>Põhjus</th>
                        <th>Nimi</th>
                        <th>Isikukood</th>
                        <th>Tulemus</th>
                        {isAdmin && <th>Toimingud</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.fileName}>
                            <td>{item.fileName}</td>
                            <td>{item?.tptskError ?? item?.error}</td>
                            <td>{item?.namedValues?.["Nimi (ees- ja perekonnanimi)"]}</td>
                            <td>{item?.namedValues?.["Isikukood:"]}</td>
                            <td>{item?.namedValues?.Score}</td>
                            {isAdmin && <td>
                                <Flex gap="xs">
                                    <Tooltip label="Kopeeri faili sisu">
                                        <IconCopy className={classes.button} onClick={() => {
                                            navigator.clipboard.writeText(JSON.stringify(item, undefined, 2));
                                            showNotification({
                                                message: "Kopeeritud",
                                                autoClose: 2000,
                                            });
                                        }} />
                                    </Tooltip>
                                    <Tooltip label="Lae fail alla">
                                        <IconDownload className={classes.button} onClick={() => {
                                            const fileToSave = new Blob([JSON.stringify(item, undefined, 2)], {
                                                type: 'application/json'
                                            });
                                            const url = window.URL.createObjectURL(fileToSave);
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', item.fileName);
                                            document.body.appendChild(link);
                                            link.click();
                                            link.remove();
                                        }} />
                                    </Tooltip>
                                    <Tooltip label="Saada päring uuesti (lubab enne muuta)">
                                        <IconSend className={classes.button} onClick={() => {
                                            setCurrentItem(JSON.stringify(item, undefined, 2));
                                        }} />
                                    </Tooltip>
                                    <Tooltip label="Kustuta fail">
                                        <IconTrash className={classes.button} onClick={() => {
                                            deleteJson(`/entrant-tests/invalid-test-1/responses/${item.fileName}`)
                                                .then(() => {
                                                    showNotification({
                                                        message: "Kustutatud",
                                                        autoClose: 2000,
                                                    });
                                                    mutate();
                                                })
                                                .catch((err) => {
                                                    showNotification({
                                                        message: err.error ?? err.message,
                                                        color: 'red',
                                                        autoClose: 10000,
                                                    });
                                                });
                                        }} />
                                    </Tooltip>
                                </Flex>
                            </td>}
                        </tr>
                    ))}
                    {data.length === 0 && (
                        <tr>
                            <td colSpan="5">Vigaseid tulemusi ei leitud</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Modal opened={currentItem !== null} onClose={setCurrentItem.bind(null, null)} title="Saada päring uuesti" centered>
                <Textarea
                    value={currentItem}
                    onChange={(e) => setCurrentItem(e.target.value)}
                    minRows={20}
                />
                <Flex mt="sm" gap="sm" justify="space-around">
                    <Button variant="outline" onClick={setCurrentItem.bind(null, null)}>Tühista</Button>
                    <Button style={{ flex: 1 }} onClick={() => {
                        let item = JSON.parse(currentItem);
                        postJson(`/public/update-test/1/${item.namedValues["Isikukood:"]}`, { score: item.namedValues.Score[0] })
                            .then(() => {
                                showNotification({
                                    message: "Saadetud",
                                    autoClose: 2000,
                                });
                                setCurrentItem(null);
                            })
                            .catch((err) => {
                                showNotification({
                                    message: err.error,
                                    color: 'red',
                                    autoClose: 10000,
                                });
                            });
                    }}
                    >Saada</Button>
                </Flex>
            </Modal>
        </Card>
    );
}