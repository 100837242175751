import { UnstyledButton, Center, createStyles, Navbar, Group, Text, Burger, MediaQuery, useMantineTheme, getStylesRef, Badge, Indicator } from '@mantine/core';
import {
  IconLogout, IconDeviceAnalytics, IconUserCheck, IconCertificate, IconMoon, IconSettings,
  IconFileUnknown
} from '@tabler/icons-react';
import { useLocalStorage } from "@mantine/hooks"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAppStore } from "./useAppStore"
import useSession from "./useSession"
import useBackendRequests from './useBackendRequests';
import useSWR from 'swr';
import Brand from './brand.js';

const useStyles = createStyles((theme, _params) => {
  const icon = getStylesRef('icon');
  return {
    navbar: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.blue[2],
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.blue[8],
      borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.blue[3]}`,
    },

    header: {
      margin: 0
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md
    },

    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.blue[8],
      padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      '&:hover': {
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.blue[3],
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.blue[8],
      opacity: 0.95,
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.blue[3],
        [`& .${icon}`]: {
          opacity: 0.9,
        },
      },
    },
  };
});

const navigationLinks = [
  { link: '/tests', label: 'Testid', icon: IconCertificate, adminOnly: false },
  { link: '/entrants', label: 'Sisseastujad', icon: IconUserCheck, adminOnly: true },
  { link: '/dashboard', exact: true, label: 'Ülevaade', icon: IconDeviceAnalytics, adminOnly: false },
  { link: '/settings', label: 'Seaded', icon: IconSettings, adminOnly: true },
];

const colorSchemeRotation = {
  auto: 'dark',
  dark: 'light',
  light: 'auto',
}
const colorSchemeLabel = {
  auto: 'Auto',
  dark: 'Tume',
  light: 'Hele',
}

export default function Navigation() {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { menuOpen, toggleMenuOpen, setMenuOpen } = useAppStore();
  const [colorScheme, setColorScheme] = useLocalStorage({ key: 'colorScheme', defaultValue: 'auto' });
  const { logout, isAdmin } = useSession()
  const navigate = useNavigate()
  const { fetcher } = useBackendRequests();
  const { data: invalidResponses } = useSWR("/entrant-tests/invalid-test-1/count", fetcher, {
    refreshInterval: 1000 * 60,
    fallbackData: { count: 0 }
  });

  const toggleColorScheme = (event) => {
    event.preventDefault();
    setColorScheme(colorSchemeRotation[colorScheme]);
  }

  const links = navigationLinks.map((item) => {
    if (item.adminOnly && !isAdmin) return null;

    return <NavLink
      to={item.link}
      key={item.label}
      item={item}
      onClick={() => setMenuOpen(false)}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </NavLink>
  });

  return (
    <Navbar
      hidden={!menuOpen}
      width={{ sm: 200, lg: 300 }}
      p="sm"
      hiddenBreakpoint="sm"
      className={classes.navbar}
    >
      <Navbar.Section grow>
        <Group mb="md" className={classes.header} position="left">
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Burger
              opened={menuOpen}
              onClick={toggleMenuOpen}
              size="sm"
              color={theme.white}
            />
          </MediaQuery>
          <Center>
            <UnstyledButton onClick={() => navigate("/tests")} p="xs">
              <Brand size={'70%'} />
            </UnstyledButton>
          </Center>
        </Group>
        {links}
        <NavLink to="/invalid-responses" item={{ link: "/invalid-responses" }} onClick={() => setMenuOpen(false)}>
          <IconFileUnknown className={classes.linkIcon} stroke={1.5} />
          <span><Indicator label={invalidResponses?.count || 0} disabled={!invalidResponses?.count} inline size={14} radius="xl" color="red" offset={-18} position="middle-end">Vigased vastused</Indicator></span>
        </NavLink>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <a href="#a" className={classes.link} onClick={toggleColorScheme}>
          <IconMoon className={classes.linkIcon} stroke={1.5} />
          <span>Vaheta teemat ({colorSchemeLabel[colorScheme]})</span>
        </a>

        <a href="#a" className={classes.link} onClick={(event) => {
          event.preventDefault()
          logout(() => {
            navigate("/login")
          })
        }}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logi välja</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}

function NavLink({ item, children, to, ...props }) {
  const { classes, cx } = useStyles();
  const currentPage = useLocation().pathname;
  const match = item.exact ? item.link === currentPage : currentPage.startsWith(item.link)

  return <Link {...props} to={to} className={cx(classes.link, { [classes.linkActive]: match })}>{children}</Link>;
}