import { useAppStore } from "../../components/useAppStore"
import { useEffect } from "react"
import { Grid, Card, Textarea, Text, Button, Input, Switch, InputWrapper, Stack, TextInput } from "@mantine/core"
import useSWR from "swr"
import { useForm } from '@mantine/form';
import { showNotification } from "@mantine/notifications"
import useBackendRequests from "../../components/useBackendRequests"

const cleanEmailInput = (input) => {
  // replace commas with newline
  // remove all spaces and double newlines
  return input.replace(/,/g, "\n").replace(/\s+/g, "\n").replace(/\n\n/g, "\n");
}

export default function Settings(props) {
  const { setHeaderTitle, addHeaderAction, removeHeaderAction } = useAppStore();
  const { postJson, fetcher } = useBackendRequests();
  let { data } = useSWR("/settings", fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    fallbackData: {
      users: '',
      rooms: '',
      ignoreRoom: false,
      allowResubmitTests: false, // this is used for fixing scoring using Google Forms quiz, disable it afterwards to avoid cheating
      interviewRooms: '',
      forwardRoomRules: '',
      receptionAdministrator: '',
    }
  });

  const form = useForm({
    initialValues: data,
  });

  useEffect(() => {
    if (!form.isDirty()) {
      const copy = {
        ...data,
        ignoreRoom: data.ignoreRoom === "true" ? true : false,
        allowResubmitTests: data.allowResubmitTests === "true" ? true : false,
      };
      form.setValues(copy);
      form.resetDirty(copy);
    }
  }, [data]);

  const saveSettings = (values) => {
    const cleanedValues = {
      ...values,
      users: cleanEmailInput(values.users ?? ""),
      rooms: values.rooms.replace(/\s+/g, ""),
      receptionAdministrator: values.receptionAdministrator,
    }
    postJson("/settings", cleanedValues).then(() => {
      showNotification({
        message: "Salvestatud",
        autoClose: 5000,
      });
      form.setValues(cleanedValues);
      form.resetDirty(cleanedValues);
    }).catch(console.log);
  }

  useEffect(() => {
    setHeaderTitle(`Seaded`);
    addHeaderAction({
      name: "save-settings",
      element: <Button key="save-settings" variant={form.isDirty() ? "filled" : "outline"} onClick={() => saveSettings(form.values)}>Salvesta</Button>,
      order: 1,
    });

    return () => {
      setHeaderTitle("");
      removeHeaderAction("save-settings");
    }
  }, [form.values, setHeaderTitle, addHeaderAction, removeHeaderAction]);

  const handleUserBlur = (event) => {
    console.log(event)
    form.setFieldValue('users', cleanEmailInput(event.target.value ?? ""));
  }

  if (!data)
    return null;

  return (
    <form>
      <Grid>
        <Grid.Col span={6}>
          <Stack>
            <Card>
              <Textarea
                label="Kasutajad"
                description="Igal real üks e-posti aadress. Komad ja tühikud eemaldatakse automaatselt."
                minRows={8}
                onBlur={handleUserBlur}
                mb="sm"
                {...form.getInputProps('users')}
              />
              <TextInput
                label="Vastuvõtu administraator"
                description={`Näidatakse õpetajatele kui nendeni on jõudnud sisseastuja kelle isikukoodi või nime ei leita.
              Kirjuta siia nimi ja ruumi number nagu näiteks "Viivika Volt ruumis A104"`}
                {...form.getInputProps('receptionAdministrator')}
              />
              <h3>Muud seaded</h3>
              <Switch
                label="Luba testide tulemuste parandamine"
                description="Selle seadistuse sisse lülitamisel lubatakse Google Formsil saata hinnetest parandused, st ka sisseastuja võib esitada esimest testi mitu korda. Aktiveeri see ainult hetkeks, kui oled esimese testi hindamist muutmas."
                {...form.getInputProps('allowResubmitTests', { type: 'checkbox' })}
              />
            </Card>
          </Stack>
        </Grid.Col>
        <Grid.Col span={6}>
          <Stack>
            <Card>
              <h2>Ruumid</h2>
              <Textarea
                label="Kõik ruumid"
                description="Komaga eraldatud nimekiri kõikidest võimalikest testide ja vestluste ruumidest mida saab rakenduses valida.
                Ruumide üles tähistamise eesmärk on vältida õpilaste valesse ruumi sattumist - programm ei luba testiga alustada kui õpilane pole vastavasse ruumi oodatud."
                autosize
                minRows={1}
                mb="sm"
                {...form.getInputProps('rooms')}
              />
              <Switch
                label="Ära kontrolli kas õpilane on õiges ruumis"
                description="Enne testi alustamist kontrollitakse kas õpilane on õiges ruumis. Selle seade sisse lülitamise korral saab õpilane testi alustada ka valest ruumist."
                {...form.getInputProps('ignoreRoom', { type: 'checkbox' })}
              />
              <h3>Automaatne ruumidesse suunamine</h3>
              <Textarea
                label="Intervjuu ruumid"
                description={`Komaga eraldatud nimekiri võimalikest vestluste ruumidest kuhu sisseastujad automaatselt pärast erialatesti jagatakse.`}
                autosize
                minRows={1}
                mb="sm"
                {...form.getInputProps('interviewRooms')} />
              <Textarea
                label="Testide edasisuunamise reeglid"
                description={`Igal real üks reegel. Igal reeglil vasakul on ruum kus sisseastuja teeb testi, paremal on ruum kuhu ta pärast testi lõpetamist suunatakse.
              Kahe ruumi vahele pane "suurem-kui" märk ">".
              Näiteks "A101>A202"
              Kui õpilane teeb testi ruumis A101, saadab tulemused ära või vastuvõtja märgib erialatesti tehtuks, siis suunatakse ta edasi ruumi A202.`}
                autosize
                minRows={4}
                {...form.getInputProps('forwardRoomRules')}
              />
            </Card>
          </Stack>
        </Grid.Col>
      </Grid>
    </form>
  )
}