import { Accordion, Button, Card, Flex, Group, Input, NumberInput, SegmentedControl, Stack, Table, Text, Tooltip } from '@mantine/core';
import { useEffect, useState, useMemo, useCallback, useRef } from "react"
import { useAppStore } from "../../components/useAppStore"
import useBackendRequests from '../../components/useBackendRequests';
import useSWR from 'swr';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';
import { useLocalStorage } from '@mantine/hooks';

export default function PracticalTestPoC({ testId = 1 }) {
  const { setHeaderTitle } = useAppStore();
  const { postJson, fetcher } = useBackendRequests();
  const [myRoom, setMyRoom] = useLocalStorage({ key: 'practicalTestRoom', defaultValue: '' });
  const [pin, setPin] = useState("");
  const [returnedTestsCount, setReturnedTestsCount] = useState(0);
  
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const { data: settings } = useSWR("/settings", fetcher, {
    fallbackData: {},
  });
  const rooms = useMemo(() => settings?.rooms?.split(",") ?? [], [settings]);
  const receptionAdministrator = settings?.receptionAdministrator ?? "";

  const { data: results, mutate: searchAgain } = useSWR(pin.length === 11 ? `/entrants/search/${pin}` : null, fetcher, {
    fallbackData: [],
  });

  // These are used to group the entrants by the time they were allowed to take the test, 2 groups a day, 2 hours apart
  // groupOffset is used to group 9:54 starters into 10:00 group instead of 8:00 group. 0.5 means 30 minutes apart
  let [groupLength, setGroupLength] = useLocalStorage({ key: 'practicalGroupLength', defaultValue: 2 }); // hours
  let [groupOffset, setGroupOffset] = useLocalStorage({ key: 'practicalGroupOffset', defaultValue: 0.33 }); // hours
  const { data: entrantsTakingTest, mutate: updateTakingTestList } = useSWR((testId && myRoom) ? `/entrant-tests/${testId}/${myRoom}` : null, fetcher, {
    fallbackData: [],
    refreshInterval: 10000,
  });

  useEffect(() => {
    setHeaderTitle("Üldtesti vastuvõtt");
    return () => {
      setHeaderTitle("");
    }
  }, [setHeaderTitle]);

  const groupedEntries = useMemo(() => {
    // Create a map to store the grouped entries
    const groupsMap = new Map();

    // Group the entries based on the allowedAt date
    entrantsTakingTest.forEach((entry) => {
      if (!entry.allowedAt) {
        return;
      }

      const createdAt = new Date(entry.allowedAt);
      const createdAtHour = createdAt.getHours() + createdAt.getMinutes() / 60 + groupOffset;
      
      if (!groupLength) {
        groupLength = 1;
      }
      const groupKey = new Date(
        createdAt.getFullYear(),
        createdAt.getMonth(),
        createdAt.getDate(),
        createdAtHour - (createdAtHour % groupLength) // Round down to the nearest multiple of groupLength hours
      ).toISOString();
      

      if (!groupsMap.has(groupKey)) {
        groupsMap.set(groupKey, []);
      }

      const group = groupsMap.get(groupKey);
      group.push(entry);
    });

    // Convert the map to an array of groups
    const groups = Array.from(groupsMap.values());

    return groups;
  }, [entrantsTakingTest, groupLength, groupOffset]);

  const handlePinChange = (event) => {
    let value = event.target.value.trim();
    setPin(value);
    // Check if it's a right barcode
    if (value.length === 9 && isNaN(value[0])) {
      setPin("");
      showNotification({
        message: `Vale triipkood "${value}"`,
        autoClose: 3000,
      });
    }
    // Remove first 11 characters if scanning again
    if (value.length > 11) {
      setPin(value.substring(11));
    }
    // Search if the barcode is 11 characters long
    if (value.length === 11) {
      searchAgain();
    }
  }

  const allowToTakeTheFirstTest = useCallback((entrant) => {
    postJson(`/entrant-tests/allow`, {
      entrantId: entrant.id,
      testId: testId,
      room: myRoom,
    }).then(() => {
      searchAgain(); // To update the acceptance part of the UI
      updateTakingTestList();
    });
  }, [postJson, myRoom, testId, searchAgain, updateTakingTestList])

  /*const endTest = useCallback((entrant, nextRoom) => {
    let data = {
      entrantId: entrant.id,
      testId: testId,
    }
    if (nextRoom) {
      data.nextRoom = nextRoom;
    }
    postJson(`/entrant-tests/end`, data).then(() => {
      updateTakingTestList();
    });
  }, [postJson, myRoom, testId, updateTakingTestList])*/

  useEffect(() => {
    const onlyOneResult = results.length === 1;
    if (onlyOneResult) {
      const isCorrectRoom = results[0].nextRoom === myRoom;
      const isCorrectDate = new Date(results[0].appointmentDate).toISOString().substring(0, 10) === new Date().toISOString().substring(0, 10);
      const isAllowed = results[0].EntrantTests.find(test => test.testId === testId)?.allowedAt;
      if (onlyOneResult && ((isCorrectRoom && isCorrectDate) || settings?.ignoreRoom === "true") && !isAllowed) {
        allowToTakeTheFirstTest(results[0]);
      }
    }
  }, [testId, myRoom, results, allowToTakeTheFirstTest, settings?.ignoreRoom]);

  return (
    <Stack>
      <Card>
        <Text fz="sm">
          Millises ruumis sa asud?
        </Text>
        <Text fz="sm">
          See aitab tuvastada kui kandidaat on vales kohas.
        </Text>
        <SegmentedControl
          value={myRoom}
          onChange={setMyRoom}
          data={rooms}
        />
      </Card>
      <Card>
        <Text fz="md" mb="xs">Sisesta kandideerija isikukood triipkoodilugejaga või käsitsi</Text>
        <Text fz="sm">Kui kandideerija on õiges ruumis, siis märgitakse ta testile lubatuks automaatselt kohe pärast sisestamist.</Text>
        <Input size="md" value={pin} onChange={handlePinChange} placeholder={"Isikukood" + (!myRoom ? " (vali enne ruum)" : "")} disabled={myRoom === ""} ref={inputRef}/>
      </Card>
      <Card>
        <h3>Otsingutulemused</h3>
        <Stack gap="md">
          {results.length > 0 && results.map((result) => {
            const isCorrectRoom = result.nextRoom === myRoom || settings?.ignoreRoom === "true";
            const isCorrectDate = new Date(result.appointmentDate).toISOString().substring(0, 10) === new Date().toISOString().substring(0, 10) || settings?.ignoreRoom === "true";
            const isAllowed = result.EntrantTests.find(test => test.testId === 1)?.allowedAt;
            return <Flex key={result.id}
              gap="md"
              justify="space-between"
              align="flex-start"
              direction="row"
              wrap="wrap"
            >
              <Text>{result.firstName} {result.lastName}</Text>
              <Text c={isCorrectDate ? '' : 'red'}>Katsete kp {dayjs(result.appointmentDate).format("dd DD.MM.YYYY") || "(puudub)"}</Text>
              <Text c={isCorrectRoom ? '' : 'red'}>{isCorrectRoom ? "Ruum: " : "Õpilane peaks olema ruumis "}{result.nextRoom || "(puudub)"}</Text>
              {(isAllowed) && <Text c="green">Testile registreeritud</Text>}
              {(isCorrectRoom && isCorrectDate && !isAllowed) && <Text c="orange">Kinnitan, palun oota...</Text>}
              {((!isCorrectRoom || !isCorrectDate) && !isAllowed) && <Button onClick={() => allowToTakeTheFirstTest(result)}>Luba testile</Button>}
            </Flex>
          })}
          {pin.length === 11 && results.length === 0 && (<>
            <Text color="red">
              Selle isikukoodiga sisseastujat ei leitud.
              Palun suuna ta vastuvõtu administraatori juurde kelleks on {receptionAdministrator ? receptionAdministrator : "(täpsustamata)"}
            </Text>
          </>)}
        </Stack>
      </Card>

      <Card>
        <Group position="apart">
          <h3>Testile lubatud</h3>
          <Group>
            <Tooltip multiline width={420} label="Mitme tunni tagant grupid tekivad. Näiteks 2 teeb grupid 8:00-10:00 ja 10:00-12:00">
              <NumberInput style={{ width: "5rem" }} size="xs" min={1} max={24} value={groupLength} onChange={setGroupLength} label="Grupi samm" placeholder="2" />
            </Tooltip>
            <Tooltip multiline width={420} label="Nihutab grupi algust, et 9:54 alustav õpilane läheks 10:00 gruppi. Ühik on tundides: 0.5 on 30min, 0.25 on 15 min. Negatiivne arv liigutab 10:05 grupist 8:00 gruppi.">
              <NumberInput style={{ width: "5rem" }} size="xs" min={-1} max={groupLength} step={0.1} precision={2} value={groupOffset} onChange={setGroupOffset} label="Grupi nihe" placeholder="0.33" />
            </Tooltip>
          </Group>
        </Group>
        <Accordion defaultValue={["0"]} variant="separated" multiple>
          {groupedEntries.map((group, index) => {
            // take the first allowedAt date from the group and round it to nearest full hour
            const firstAllowedAt = group.reduce((acc, entrantTest) => {
              if (!entrantTest.allowedAt) return acc;
              if (!acc) return entrantTest.allowedAt;
              return entrantTest.allowedAt < acc ? entrantTest.allowedAt : acc;
            }, null);
            const date = dayjs(firstAllowedAt).startOf("hour").format("DD.MM.YYYY HH:mm");
            return <Accordion.Item key={index} value={index.toString()}>
              <Accordion.Control>Grupp {date}, registreeritud {group.length} {group.length === 1 ? "õpilane" : "õpilast"}</Accordion.Control>
              <Accordion.Panel>
                <Table>
                  <thead>
                    <tr>
                      <th>Nimi</th>
                      <th>Isikukood</th>
                      <th>Alustas</th>
                      <th>Lõpetas</th>
                      <th>Testi tulemus</th>
                      <th>Järgmine ruum</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.map((entrantTest) => {
                      const entrant = entrantTest.Entrant;
                      return <tr key={entrantTest.entrantId}>
                        <td>{entrant.firstName} {entrant.lastName}</td>
                        <td>{entrant.pin}</td>
                        <td>{entrantTest?.startedAt ? dayjs(entrantTest?.startedAt).format("HH:mm") : "Pole alustanud"}</td>
                        <td>{entrantTest?.returnedAt ? dayjs(entrantTest?.returnedAt).format("HH:mm") : "Pole lõpetanud"}</td>
                        <td>{entrantTest?.returnedAt ? entrantTest.score : ""}</td>
                        <td>{entrantTest.returnedAt !== null && (
                          entrant.nextRoom ?? "(puudub)"
                          /*<SegmentedControl
                            value={entrant.nextRoom}
                            onChange={(nextRoom) => endTest(entrant, nextRoom)}
                            data={rooms}
                            color={entrant.nextRoom === myRoom ? "red" : "green"}
                          />*/
                        )}</td>
                      </tr>
                    })}
                  </tbody>
                </Table>
              </Accordion.Panel>
            </Accordion.Item>
          })}
        </Accordion>
      </Card>
    </Stack>
  )
}