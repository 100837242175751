import { Box, Button, Group, Text, useMantineTheme, rem, Accordion, Badge } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { useAppStore } from "../../components/useAppStore"
import { useNavigate } from "react-router-dom"
import useBackendRequests from '../../components/useBackendRequests';
import { IconUpload, IconFileSpreadsheet, IconX } from '@tabler/icons-react';
import { Dropzone } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';

export default function ImportEntrants() {
    const theme = useMantineTheme();
    const { setHeaderTitle } = useAppStore();
    const openDialogRef = useRef(null);
    const { postJson } = useBackendRequests();
    const navigate = useNavigate();
    const [importResults, setImportResults] = useState(null);

    useEffect(() => {
        setHeaderTitle("Sisseastujate import");
        return () => {
            setHeaderTitle("");
        }
    }, []);

    const sendFile = async (files) => {
        const formData = new FormData();
        console.log(files)
        formData.append('file', files[0]);
        postJson('/entrants/import/sais', formData).then(response => {
            setImportResults(response);
            if (!response?.errors && !response?.newRecords) {
                showNotification({
                    title: 'Failed to import entrants',
                    message: 'Unexpected error, see server logs',
                    color: 'red',
                    autoClose: 8000,
                });
            }
        }).catch(response => {
            if (!response?.errors && !response?.newRecords) {
                showNotification({
                    title: 'Failed to import entrants',
                    message: 'Unexpected error, see server logs',
                    color: 'red',
                    autoClose: 8000,
                });
            }

            if (response?.errors) {
                setImportResults(response);
            }
        });
    }

    return (
        <>
            <Dropzone
                onDrop={sendFile}
                onReject={(files) => console.log('rejected files', files)}
                openRef={openDialogRef}
                name="file"
                multiple={false}
            >
                <Group position="center" spacing="xl" style={{ minHeight: rem(220), pointerEvents: 'none' }}>
                    <Dropzone.Accept>
                        <IconUpload
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                        Mitme faili korraga laadimine pole toetatud
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconFileSpreadsheet size="3.2rem" stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                        <Text size="xl" inline>
                            Drag files here or click to select files
                        </Text>
                    </div>
                </Group>
            </Dropzone>

            <Group position="center" my="md">
                <Button onClick={() => openDialogRef.current()}>Select files</Button>
            </Group>

            <Accordion variant="separated" chevronPosition="left">
                <Accordion.Item value="help">
                    <Accordion.Control>Kust leiab vajalikud failid?</Accordion.Control>
                    <Accordion.Panel>
                        Andmed tulevad <a href="https://admin.sais.ee/" target='blank'>SAISist</a>. Veendu, et oled vastuvõtutöötaja rollis.<br/>
                        <br/>
                        <strong>Esimese väljundi leiame <a href="https://admin.sais.ee/ExamProtocols" target='blank'>Protokollid &gt; Üldteadmiste test (pk arvutis) &gt; Roheline nupp "Protokoll"</a><br/></strong>
                        Seda tuleb võtta iga hommik, sest eilse päeva hilisõhtul võib veel tulla sooviavaldusi selleks päevaks.<br/>
                        Vali tänane kuupäev, seejärel vali "Kuva lehel" 150 või suurem, vajuta "Otsi".<br/>
                        Seejärel vajuta lehe all servas "Väljund Excelisse".<br/>
                        <br/>
                        <strong>Teine väljund tuleb <a href="https://admin.sais.ee/Queries" target='blank'>Avaldused &gt; Päringud</a><br/></strong>
                        Seda kasutame eriala/rühma ja varasemate õpingute info saamiseks.<br/>
                        Otsi sealt nime järgi "vastuvõturakendusse" (kk lõpus on keskkooli baasil), vajuta silma ikoonile.
                        Nendest voldiatavatest sektsioonidest on oluline seadistada "Avaldused" sektsioonist "Avalduse staatus", "Õppevorm avaldusel" ja "Õppeaasta".
                        Linnuke välja ees tähistab, et tulemus peab minema väljundisse ja valik välja taga on filter.<br/>
                        "Avalduse staatus" peaks olema <Badge px="xs">Lubatud sisseastumiskatsele</Badge> ja "Õppevorm avaldusel" peaks olema kevadel <Badge px="xs">statsionaarne õpe - põhikooli baasil</Badge> ja sügisel <Badge px="xs">mittestatsionaarne õpe</Badge><br/>
                        Nüüd kõige all on must nupp "Genereeri tulemused kohe", see laeb vb pisut, vaata kas "Viimati genereeritud" kuupäev on muutunud ja siis vajuta Väljund Excelisse.
                        Selle xls faili peab tegema Exceliga lahti ja salvestama ümber xlsx formaati.<br/>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            {importResults && (
                <Box sx={{ height: 300 }}>
                    {importResults.errors && (
                        <Text color="red" size="sm">
                            {importResults.errors.length} error(s) found
                            {importResults.errors.map(error => (
                                <div key={error}>{JSON.stringify(error)}</div>
                            ))}
                        </Text>
                    )}
                    {importResults?.newRecords && (<>
                        <h2>Kokku kandeid failis ({importResults.totalRecords})</h2>
                        <h2>Lisatud uued sisseastujad ({importResults.newRecords.length})</h2>
                        <DataTable
                            withBorder
                            highlightOnHover
                            records={importResults.newRecords}
                            onRowClick={(entrant, rowIndex) => {
                                navigate(`/entrants/${entrant.id}`);
                            }}
                            columns={[
                                { accessor: 'name', render: ({ firstName, lastName }) => `${firstName} ${lastName}`, title: 'Nimi' },
                                { accessor: 'pin', title: 'Isikukood' },
                                { accessor: 'program', title: 'Eriala' },
                                { accessor: 'firstRoom', title: 'Ruum' },
                                { accessor: 'appointmentDate', title: 'Katsete kp' },
                            ]}
                        />
                        <h2>Juba olemasolevad mida muudeti ({importResults.updatedRecords.length})</h2>
                        <DataTable
                            withBorder
                            highlightOnHover
                            records={importResults.updatedRecords}
                            onRowClick={(entrant, rowIndex) => {
                                navigate(`/entrants/${entrant.id}`);
                            }}
                            columns={[
                                { accessor: 'name', render: ({ firstName, lastName }) => `${firstName} ${lastName}`, title: 'Nimi' },
                                { accessor: 'pin', title: 'Isikukood' },
                                { accessor: 'program', title: 'Eriala' },
                                { accessor: 'firstRoom', title: 'Ruum' },
                                { accessor: 'appointmentDate', title: 'Katsete kp' },
                            ]}
                        />
                    </>)}
                </Box>
            )}
        </>
    );
}