import {Title, SimpleGrid, Button, Card, Text, Grid, Input, Textarea } from "@mantine/core";
import {useEffect, useMemo} from "react"
import {useAppStore} from "../../components/useAppStore"
import {NavLink, useParams} from "react-router-dom"
import useSWR from "swr"
import dayjs from "dayjs"
import useBackendRequests from "../../components/useBackendRequests"
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import useSession from "../../components/useSession";

const TestNames = {
  "1": "Üldtest",
  "2": "Erialatest",
  "3": "Vestlus",
}

const fallbackData = {
  firstName: "",
  lastName: "",
  pin: "",
  firstRoom: "",
  assignedRoom: "",
  nextRoom: "",
  comments: "",
  language: "",
  program: "",
  appointmentDate: "",
  createdAt: "",
  saisUpdatedAt: "",
  EntrantTests: [],
}

export default function EditEntrants() {
  const {setHeaderTitle, addHeaderAction, removeHeaderAction} = useAppStore();
  const {id} = useParams();
  const {fetcher, putJson} = useBackendRequests();
  const { isAdmin } = useSession();

  const { data: settings } = useSWR("/settings", fetcher, {
    fallbackData: {},
  });
  const rooms = useMemo(() => settings?.rooms?.split(",") ?? [], [settings]);

  const {data} = useSWR(`/entrants/${id}`, fetcher, {
    fallbackData,
  });
  const fullName = `${data?.firstName} ${data?.lastName}`;

  const form = useForm({
    initialValues: data,
  });

  const saveEntrant = (values) => {
    const cleanedValues = {
      assignedRoom: values.assignedRoom,
      comments: values.comments,
    }
    putJson(`/entrants/${id}`, cleanedValues).then(() => {
      showNotification({
        message: "Salvestatud",
        autoClose: 5000,
      });
      form.setValues(cleanedValues);
      form.resetDirty(cleanedValues);
    });
  }

  useEffect(() => {
    setHeaderTitle(`Sisseastuja ${fullName} ${data?.pin}`);
    // addHeaderAction({
    //   name: "save-entrant",
    //   element: <Button key="save-settings" variant={form.isDirty() ? "filled" : "outline"}onClick={() => saveEntrant(form.values)}>Salvesta</Button>,
    //   order: 1,
    // });

    return () => {
      setHeaderTitle("");
      removeHeaderAction("save-entrant");
    }
  }, [data, form.values, removeHeaderAction, setHeaderTitle]);

  useEffect(() => {
    if (!form.isDirty()) {
      let values = {
        assignedRoom: data?.nextRoom ?? "",
        comments: data?.comments ?? "",
      };
      form.setValues(values);
      form.resetDirty(values);
    }
  }, [data]);

  const testResults = useMemo(() => {
    return Object.entries(TestNames).map(([testId, name]) => {
      // eslint-disable-next-line eqeqeq
      let test = data?.EntrantTests.find(test => test.testId == testId);
      return {
        testId,
        name,
        score: test?.score ?? 0,
        ...test
      }
    });
  }, [data]);

  const entrantsItemsData = [
    { label: 'Eesnimi', data: data?.firstName },
    { label: 'Perekonnanimi', data: data?.lastName },
    { label: 'Isikukood', data: data?.pin },
    { label: 'Eriala', data: data?.program },
    { label: 'Järgmine ruum kuhu õpilast oodatakse', data: data?.nextRoom },
    { label: 'Katsete kuupäev', data: data?.appointmentDate ? dayjs(data.appointmentDate).format('DD.MM.YYYY HH:mm') : "" },
    { label: 'Süsteemi lisatud', data: data?.createdAt ? dayjs(data.createdAt).format('DD.MM.YYYY HH:mm') : "" },
    { label: 'Viimane muudatus SAISist', data: data?.saisUpdatedAt ? dayjs(data.saisUpdatedAt).format('DD.MM.YYYY HH:mm') : "" },
  ];

  const entrantsData = entrantsItemsData.map((item) => (
    <>
      <Text key={item.label} fz="xs">{item.label}<Text fz="sm">{item.data}</Text></Text>
    </>
  ));

  const testResultsData = testResults.map((test, index) => (  
    <Card key={test.testId} p="xs" withBorder>  
      <Title mb="sm" order={3}>{test.name}</Title>
      <Text mt="xs" fz="xs">Punkte: <Text fz="sm">{test.score}</Text></Text> 
      {(test?.gradedBy != null) && (<><Text mt="xs" fz="xs">Hindaja: <Text fz="sm">{test?.gradedBy}</Text></Text></>)}
      {index === 0 && <><Text mt="xs" fz="xs">Tuli testile: <Text fz="xs">{test?.allowedAt == null ? "Pole tulnud" : dayjs(test?.allowedAt).format('dddd DD.MM.YYYY HH:mm')}</Text></Text></>}
      <><Text mt="xs" fz="xs">Alustas testiga: <Text fz="xs">{test?.startedAt == null ? "Pole alustanud" : dayjs(test?.startedAt).format('dddd DD.MM.YYYY HH:mm')}</Text></Text></>
      <><Text mt="xs" mb="xs" fz="xs">Lõpetas testi: <Text fz="xs">{test?.returnedAt == null ? "Pole lõpetanud" : (dayjs(test?.returnedAt).format('dddd DD.MM.YYYY HH:mm') + ` (~${dayjs(test?.returnedAt).diff(test?.startedAt, 'minutes')} min)`)}</Text></Text></>
      {(test.testId === 3 && test.returnedAt) && <><NavLink to={`/interview/${id}`}>Vestluse protokoll</NavLink></>}
    </Card>
  ));

  if (!isAdmin) {
    return null;
  }

  return (
    <Grid>
      <Grid.Col md={8}>
        <Card>
          <Title mb="md" order={2}>Andmed</Title>
          <SimpleGrid cols={3}>
            {entrantsData}
          </SimpleGrid>
          
        </Card>
        <Card mt="md">
          <Title mb="sm" order={2}>Testid</Title>
          <SimpleGrid cols={3}
            breakpoints={[
              { maxWidth: '62rem', cols: 3, spacing: 'xs' },
              { maxWidth: '48rem', cols: 2, spacing: 'xs' },
              { maxWidth: '36rem', cols: 1, spacing: 'xs' },
            ]}    
          >
            {testResultsData}
          </SimpleGrid>
        </Card>
      </Grid.Col>
      <Grid.Col md={4}>
      <Card>
           <Input.Wrapper label="Kommentaarid">
            <Textarea mb="xs" autosize minRows={2} maxRows={6} {...form.getInputProps("comments")} />
            <Button key="save-settings" variant={form.isDirty() ? "filled" : "outline"} onClick={() => saveEntrant(form.values)}>Salvesta</Button>
          </Input.Wrapper>
        </Card>
        </Grid.Col>
    </Grid>
  );
}