import React, { useEffect } from 'react'
import { NumberInput, MediaQuery, Container, Grid, Title, Button, Center, Card, Group, useMantineTheme } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import Brand from '../../components/brand.js';
import { useForm } from '@mantine/form';
import { BACKEND_API_URL } from '../../App.js';
import { showNotification } from '@mantine/notifications';

export default function YldtestLogin() {
	const form = useForm({
		initialValues: {
			pin: '',
		},
		validate: (values) => ({
			pin: values.pin.toString().length < 11 ? 'Palun sisesta oma isikukood' : null,
		}),
	});
	const theme = useMantineTheme();

	const handleSubmit = (values) => {
		fetch(BACKEND_API_URL + '/public/start-test/1/'+values.pin.toString(), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': process.env.REACT_APP_START_TEST_API_KEY || 'test',
			}
		})
			.then(response => response.json())
			.then(data => {
				if (data.allowed) {
					console.log('Success:', data);
					if (data.url)
						window.location.href = data.url;
				} else {
					console.error('Error:', data);
					showNotification({
						title: "Viga",
						message: "Kontrolli üle oma isikukood. Kui viga tuleb ikka küsi abi. Veakood: " + data.error,
						color: 'red',
						autoClose: false,
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
				showNotification({
					title: "Viga",
					message: error.message,
					color: 'red',
					autoClose: false,
				});
			});
	}

	useEffect(() => {
		document.body.classList.add('login');
		document.body.classList.add(theme.colorScheme);
	}, []);

	return (
		<Container size="md">
			<MediaQuery query="(min-width: 687px)" styles={{ height: "100vh" }}>
				<Grid grow justify="center" align="center">
					<Grid.Col span={6} mt="lg">
						<Center>
							<Brand size={300} />
						</Center>
					</Grid.Col>
					<Grid.Col span={6}>
						<Card shadow="sm" p="lg" radius="xl">
							<Title ta="center" mb="sm" color="blue.8" order={1}>Logi sisse</Title>
							<form onSubmit={form.onSubmit(handleSubmit)}>
								<NumberInput
									size="md"
									placeholder="12345678901"
									label="Isikukood"
									type="number"
									hideControls
									{...form.getInputProps('pin')}
								/>
								<Group position="center" mt="xs">
									<Button fullWidth type="submit" size='md' radius="md">Alusta testiga <IconChevronRight size={16} strokeWidth={4} /></Button>
								</Group>
							</form>
						</Card>
					</Grid.Col>
				</Grid>
			</MediaQuery>
		</Container>
	)
}